/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
h1 {
  font-family: "Red Hat Display", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Oxygen, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 3.2rem;
  color: #212121;
  font-weight: 700;
  text-transform: uppercase;
  margin: 1rem 0 2rem; }

h2 {
  font-family: "Red Hat Display", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Oxygen, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 2.8rem;
  color: #212121;
  font-weight: 700;
  text-transform: uppercase;
  margin: 1rem 0 2rem; }

h3 {
  font-family: "Red Hat Display", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Oxygen, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.8rem;
  color: #212121;
  font-weight: 700;
  text-transform: uppercase;
  margin: 1rem 0 2rem; }

h4 {
  font-family: "Red Hat Display", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Oxygen, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.6rem;
  color: #212121;
  font-weight: 700;
  text-transform: uppercase;
  margin: 1rem 0 2rem; }

h5 {
  font-family: "Red Hat Display", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Oxygen, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.3rem;
  color: #212121;
  font-weight: 700;
  text-transform: uppercase;
  margin: 1rem 0 2rem; }

h6 {
  font-family: "Red Hat Display", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Oxygen, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.2rem;
  color: #212121;
  font-weight: 700;
  text-transform: uppercase;
  margin: 1rem 0 2rem; }

a, a:visited {
  color: #FF7442;
  text-decoration: none; }

a:hover, a:focus, a:visited:hover, a:visited:focus {
  color: #FF7442;
  text-decoration: none; }

ul,
ol {
  margin: 0 0 2.5rem;
  padding: 0 0 0 4rem; }
  ul > li,
  ol > li {
    margin: 0 0 1rem; }

.hidden-title {
  visibility: hidden;
  width: 0;
  font-size: 0; }

.text-normal {
  font-weight: normal; }

.text-bold {
  font-weight: bold; }

.text-upper {
  text-transform: uppercase; }
