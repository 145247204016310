/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-product-finder {
  font-size: 1rem;
  position: relative;
  padding-bottom: 2rem;
  transition: height 0.3s, padding-bottom 0.3s;
  overflow: hidden;
  margin: 0 0 3rem; }
  @media (min-width: 48em) {
    .cs-product-finder {
      margin: 0 0 4rem; } }
  .cs-product-finder--skip-spacing {
    margin: 0; }
  .cs-product-finder__back-button {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: uppercase;
    border-radius: 25px;
    font-family: "Red Hat Display";
    padding: 8px 25px;
    font-size: 1.2rem;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    will-change: transform, background-color, border-color, fill, color;
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.3s 0.4s, 0.15s linear, border-color 0.15s linear, color 0.15s linear;
    left: 50%;
    transform: translateX(-50%) scale(0); }
    .cs-product-finder__back-button.medium {
      padding: 11px 25px;
      font-size: 1.4rem; }
    .cs-product-finder__back-button.large {
      padding: 13px 25px;
      font-size: 1.6rem; }
    .cs-product-finder__back-button::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-product-finder__back-button, .cs-product-finder__back-button:visited {
      cursor: pointer;
      border-radius: 25px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid transparent;
      color: #ffffff;
      background-color: #000; }
      .cs-product-finder__back-button *, .cs-product-finder__back-button:visited * {
        color: #ffffff;
        fill: #FF4604; }
    .cs-product-finder__back-button:hover:not([disabled]), .cs-product-finder__back-button:focus:not([disabled]), .cs-product-finder__back-button:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid transparent;
      color: #ffffff;
      background-color: #3E3E3E; }
      .cs-product-finder__back-button:hover:not([disabled]) *, .cs-product-finder__back-button:focus:not([disabled]) *, .cs-product-finder__back-button:active:not([disabled]) * {
        color: #ffffff;
        fill: #ffffff; }
    .cs-product-finder__back-button[disabled] {
      opacity: 0.65;
      cursor: auto; }
    .cs-product-finder__back-button-span {
      margin-top: 0;
      z-index: 1;
      font-weight: 700;
      vertical-align: baseline;
      display: inline-block;
      line-height: 1.275em;
      letter-spacing: normal;
      padding-left: 4em; }
    .cs-product-finder__back-button-icon {
      max-width: 1em;
      max-height: 1.4rem;
      z-index: 2;
      height: auto;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      margin-left: 0;
      left: 1em; }
    .cs-product-finder__back-button--visible {
      opacity: 1;
      transform: translateX(-50%) scale(1); }
      .cs-product-finder__step .cs-product-finder__back-button--visible {
        display: none; }
  .cs-product-finder__form {
    display: none; }
  .cs-product-finder__step {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    transition: opacity 0.3s 0.4s;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1; }
    .cs-product-finder__step--current {
      position: static;
      opacity: 1;
      z-index: 1; }
  .cs-product-finder__step-header {
    font-size: 1rem;
    clear: both;
    padding: 2em 0;
    margin: 2em 0;
    width: 100%; }
  .cs-product-finder__step-title {
    display: block;
    text-align: center;
    align-items: center;
    margin: 0;
    font-size: 2.6em;
    font-weight: 700;
    color: #212121;
    text-transform: none; }
    @media (min-width: 48em) {
      .cs-product-finder__step-title {
        display: flex; } }
  .cs-product-finder__step-description {
    color: #3E3E3E;
    font-size: 1.3em;
    font-weight: 400;
    text-transform: none;
    margin: 0;
    display: none;
    text-align: center; }
    @media (min-width: 48em) {
      .cs-product-finder__step-description {
        display: block; } }
  .cs-product-finder__step-options {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .cs-product-finder__step-option {
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 1rem;
    border: 1px solid #f0f0f0;
    border-radius: 0.5rem;
    transition: 0.3s border-color;
    overflow: hidden; }
    .cs-product-finder__step-option--checked, .cs-product-finder__step-option:hover {
      border-color: #FF4604; }
    @media (max-width: 47.99em) {
      .cs-product-finder__step--options-mobile_1 .cs-product-finder__step-option {
        flex-basis: calc( 100% - 0rem);
        margin-right: 1rem; }
        .cs-product-finder__step--options-mobile_1 .cs-product-finder__step-option:nth-child(1n + 1) {
          margin-right: 0; } }
    @media (max-width: 47.99em) {
      .cs-product-finder__step--options-mobile_2 .cs-product-finder__step-option {
        flex-basis: calc( 50% - 0.5rem);
        margin-right: 1rem; }
        .cs-product-finder__step--options-mobile_2 .cs-product-finder__step-option:nth-child(2n + 2) {
          margin-right: 0; } }
    @media (max-width: 47.99em) {
      .cs-product-finder__step--options-mobile_3 .cs-product-finder__step-option {
        flex-basis: calc( 33.33333% - 0.66667rem);
        margin-right: 1rem; }
        .cs-product-finder__step--options-mobile_3 .cs-product-finder__step-option:nth-child(3n + 3) {
          margin-right: 0; } }
    @media (max-width: 47.99em) {
      .cs-product-finder__step--options-mobile_4 .cs-product-finder__step-option {
        flex-basis: calc( 25% - 0.75rem);
        margin-right: 1rem; }
        .cs-product-finder__step--options-mobile_4 .cs-product-finder__step-option:nth-child(4n + 4) {
          margin-right: 0; } }
    @media (max-width: 47.99em) {
      .cs-product-finder__step--options-mobile_5 .cs-product-finder__step-option {
        flex-basis: calc( 20% - 0.8rem);
        margin-right: 1rem; }
        .cs-product-finder__step--options-mobile_5 .cs-product-finder__step-option:nth-child(5n + 5) {
          margin-right: 0; } }
    @media (max-width: 47.99em) {
      .cs-product-finder__step--options-mobile_6 .cs-product-finder__step-option {
        flex-basis: calc( 16.66667% - 0.83333rem);
        margin-right: 1rem; }
        .cs-product-finder__step--options-mobile_6 .cs-product-finder__step-option:nth-child(6n + 6) {
          margin-right: 0; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-product-finder__step--options-tablet_1 .cs-product-finder__step-option {
    flex-basis: calc( 100% - 0rem);
    margin-right: 1rem; }
    .cs-product-finder__step--options-tablet_1 .cs-product-finder__step-option:nth-child(1n + 1) {
      margin-right: 0; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-product-finder__step--options-tablet_2 .cs-product-finder__step-option {
    flex-basis: calc( 50% - 0.5rem);
    margin-right: 1rem; }
    .cs-product-finder__step--options-tablet_2 .cs-product-finder__step-option:nth-child(2n + 2) {
      margin-right: 0; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-product-finder__step--options-tablet_3 .cs-product-finder__step-option {
    flex-basis: calc( 33.33333% - 0.66667rem);
    margin-right: 1rem; }
    .cs-product-finder__step--options-tablet_3 .cs-product-finder__step-option:nth-child(3n + 3) {
      margin-right: 0; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-product-finder__step--options-tablet_4 .cs-product-finder__step-option {
    flex-basis: calc( 25% - 0.75rem);
    margin-right: 1rem; }
    .cs-product-finder__step--options-tablet_4 .cs-product-finder__step-option:nth-child(4n + 4) {
      margin-right: 0; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-product-finder__step--options-tablet_5 .cs-product-finder__step-option {
    flex-basis: calc( 20% - 0.8rem);
    margin-right: 1rem; }
    .cs-product-finder__step--options-tablet_5 .cs-product-finder__step-option:nth-child(5n + 5) {
      margin-right: 0; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-product-finder__step--options-tablet_6 .cs-product-finder__step-option {
    flex-basis: calc( 16.66667% - 0.83333rem);
    margin-right: 1rem; }
    .cs-product-finder__step--options-tablet_6 .cs-product-finder__step-option:nth-child(6n + 6) {
      margin-right: 0; } }
    @media (min-width: 64em) {
      .cs-product-finder__step--options-desktop_1 .cs-product-finder__step-option {
        flex-basis: calc( 100% - 0rem);
        margin-right: 1rem;
        /**
                     * flex-basis does not respect box-sizing: border-box
                     * It is fixed on Edge but not on IE11:
                     * https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/106816/
                     * To properly calculate flex-basis we have to add gap (margin)
                     */ }
        .cs-product-finder__step--options-desktop_1 .cs-product-finder__step-option:nth-child(1n + 1) {
          margin-right: 0; }
        .ie11 .cs-product-finder__step--options-desktop_1 .cs-product-finder__step-option {
          flex-basis: calc( 100% - 1rem); } }
    @media (min-width: 64em) {
      .cs-product-finder__step--options-desktop_2 .cs-product-finder__step-option {
        flex-basis: calc( 50% - 0.5rem);
        margin-right: 1rem;
        /**
                     * flex-basis does not respect box-sizing: border-box
                     * It is fixed on Edge but not on IE11:
                     * https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/106816/
                     * To properly calculate flex-basis we have to add gap (margin)
                     */ }
        .cs-product-finder__step--options-desktop_2 .cs-product-finder__step-option:nth-child(2n + 2) {
          margin-right: 0; }
        .ie11 .cs-product-finder__step--options-desktop_2 .cs-product-finder__step-option {
          flex-basis: calc( 50% - 1.5rem); } }
    @media (min-width: 64em) {
      .cs-product-finder__step--options-desktop_3 .cs-product-finder__step-option {
        flex-basis: calc( 33.33333% - 0.66667rem);
        margin-right: 1rem;
        /**
                     * flex-basis does not respect box-sizing: border-box
                     * It is fixed on Edge but not on IE11:
                     * https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/106816/
                     * To properly calculate flex-basis we have to add gap (margin)
                     */ }
        .cs-product-finder__step--options-desktop_3 .cs-product-finder__step-option:nth-child(3n + 3) {
          margin-right: 0; }
        .ie11 .cs-product-finder__step--options-desktop_3 .cs-product-finder__step-option {
          flex-basis: calc( 33.33333% - 1.66667rem); } }
    @media (min-width: 64em) {
      .cs-product-finder__step--options-desktop_4 .cs-product-finder__step-option {
        flex-basis: calc( 25% - 0.75rem);
        margin-right: 1rem;
        /**
                     * flex-basis does not respect box-sizing: border-box
                     * It is fixed on Edge but not on IE11:
                     * https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/106816/
                     * To properly calculate flex-basis we have to add gap (margin)
                     */ }
        .cs-product-finder__step--options-desktop_4 .cs-product-finder__step-option:nth-child(4n + 4) {
          margin-right: 0; }
        .ie11 .cs-product-finder__step--options-desktop_4 .cs-product-finder__step-option {
          flex-basis: calc( 25% - 1.75rem); } }
    @media (min-width: 64em) {
      .cs-product-finder__step--options-desktop_5 .cs-product-finder__step-option {
        flex-basis: calc( 20% - 0.8rem);
        margin-right: 1rem;
        /**
                     * flex-basis does not respect box-sizing: border-box
                     * It is fixed on Edge but not on IE11:
                     * https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/106816/
                     * To properly calculate flex-basis we have to add gap (margin)
                     */ }
        .cs-product-finder__step--options-desktop_5 .cs-product-finder__step-option:nth-child(5n + 5) {
          margin-right: 0; }
        .ie11 .cs-product-finder__step--options-desktop_5 .cs-product-finder__step-option {
          flex-basis: calc( 20% - 1.8rem); } }
    @media (min-width: 64em) {
      .cs-product-finder__step--options-desktop_6 .cs-product-finder__step-option {
        flex-basis: calc( 16.66667% - 0.83333rem);
        margin-right: 1rem;
        /**
                     * flex-basis does not respect box-sizing: border-box
                     * It is fixed on Edge but not on IE11:
                     * https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/106816/
                     * To properly calculate flex-basis we have to add gap (margin)
                     */ }
        .cs-product-finder__step--options-desktop_6 .cs-product-finder__step-option:nth-child(6n + 6) {
          margin-right: 0; }
        .ie11 .cs-product-finder__step--options-desktop_6 .cs-product-finder__step-option {
          flex-basis: calc( 16.66667% - 1.83333rem); } }
  .cs-product-finder__option-sizer {
    padding-bottom: 100%; }
  .cs-product-finder__option-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /**
         * We do not autoprefix grid since IE11 has many bugs in its implementation
         * but in this small case it is working fine as tested.
         */
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr; }
    .ie11 .cs-product-finder__option-content {
      -ms-grid-rows: 1fr auto;
      grid-template-rows: 1fr auto; }
  .cs-product-finder__option-image {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    -ms-grid-row: 1;
    -ms-grid-column-align: center;
    grid-row: 1; }
  .cs-product-finder__option-label {
    width: 100%;
    font-size: 1.4rem;
    justify-self: flex-end;
    margin-top: auto;
    font-weight: bold;
    padding-top: 1rem;
    text-align: center;
    -ms-grid-row: 2;
    grid-row: 2; }
