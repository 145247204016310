/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Remove browser's margins, paddings and list style rules
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
.cs-breadcrumbs {
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  font-weight: 400;
  margin-bottom: 10px; }
  @media (max-width: 47.99em) {
    .cs-breadcrumbs {
      margin-bottom: 0; } }
  .cs-breadcrumbs__list {
    margin: 0;
    padding: 0;
    list-style: none; }
  .cs-breadcrumbs__item {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0 1.95em 0 0;
    cursor: pointer;
    font-size: 1.4rem; }
    .cs-breadcrumbs__item:last-child {
      padding: 0;
      color: #6E6E6E;
      cursor: default; }
    .cs-breadcrumbs__item:not(:last-child):after {
      position: absolute;
      top: 50%;
      right: 0.8em;
      content: '';
      width: 5px;
      height: 100%;
      transform: translateY(-50%);
      line-height: 1;
      color: #FF4604;
      background: url("../images/icons/arrow-right@1x.svg");
      background-size: 5px;
      background-repeat: no-repeat;
      background-position: center; }
  .cs-breadcrumbs__link {
    position: relative;
    display: inline-block;
    color: #FF4604; }
    .cs-breadcrumbs__link:visited {
      color: #FF4604; }
    .cs-breadcrumbs__link:hover {
      text-decoration: none;
      color: #FF4604; }
    .cs-breadcrumbs__link:after {
      bottom: 0;
      left: 0;
      height: 1px;
      background: #FF4604;
      width: 100%;
      position: absolute;
      content: '';
      display: block; }
    .cs-breadcrumbs__link:visited:hover, .cs-breadcrumbs__link:hover {
      color: #FF4604; }
      .cs-breadcrumbs__link:visited:hover:after, .cs-breadcrumbs__link:hover:after {
        background: #FF4604; }
  .cs-breadcrumbs__separator-icon {
    position: absolute;
    top: 50%;
    right: 0.5em;
    transform: translateY(-50%);
    width: 0.8em;
    height: 0.8em;
    fill: #FF4604; }
