@import 'config/variables';
@import 'utils/component-spacing';
@import 'components/button/mixin';
@import 'utils/reset-list-style';

$button-fallback_type: $button_default-type !default;
$button-fallback_icon-pos: $button_default-icon-pos !default;

$button-component_type: $button_default-type !default;
$button-component_icon-pos: $button_default-icon-pos !default;
$button-component_horizontal-position: 50% !default;
$button-component_horizontal-translation: translateX(-50%) !default;

.#{$ns}button {
    @include button(
        $type: $button-fallback_type,
        $icon_pos: $button-fallback_icon-pos
    );

    &__span {
        @include button_span(
            $type: $button-fallback_type,
            $icon_pos: $button-fallback_icon-pos
        );
    }

    &__icon {
        @include button_icon(
            $type: $button-fallback_type,
            $icon_pos: $button-fallback_icon-pos
        );
    }
}

.#{$ns}button-component {
    @include button(
        $type: $button-component_type,
        $icon_pos: $button-component_icon-pos
    );
    @include component-spacing();

    position: relative;
    left: $button-component_horizontal-position;
    transform: $button-component_horizontal-translation;

    &__span {
        @include button_span(
            $type: $button-component_type,
            $icon_pos: $button-component_icon-pos
        );
    }

    &__icon {
        @include button_icon(
            $type: $button-component_type,
            $icon_pos: $button-component_icon-pos
        );
    }

    &__amount {
        &:before {
            content: '(';
        }
        &:after {
            content: ')';
        }
    }
}

.button {
    &.secondary {
        @include button(
            $type: 'secondary',
            $icon_pos: null,
            $text_transform: uppercase,
            $border-radius: 25px
        );
    }
    &.primary {
        @include button(
            $type: 'primary',
            $icon_pos: null,
            $text_transform: uppercase,
            $border-radius: 25px
        );
        font-size: 1.2rem;
        line-height: 16px;
    }
}

.icon__arrow-round-right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    &:after {
        content: '';
        display: block;
        background: url("../images/icons/arrow@1x.svg") no-repeat;
        background-size: contain;
        background-position: center;
        width: 27px;
        height: 27px;
        margin-left: 15px;
    }
}

.icon__arrow-round-left {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    &:before {
        content: '';
        display: block;
        background: url("../images/icons/arrowleft@1x.svg") no-repeat;
        background-size: contain;
        background-position: center;
        width: 27px;
        height: 27px;
        margin-right: 15px;
    }
}


.links-list {
    @include reset-list-style();
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @include media('<=tablet') {
        flex-wrap: wrap;
        margin-bottom: 20px;
    }

    &__item {
        flex: 1 1;

        @include media('<=tablet') {
            flex: 1 1 50%;
            margin: 0;
        }
    }

    &__link {
        color: $color_black;
        font-size: 18px;
        line-height: 26px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        letter-spacing: 0.4px;
        padding: 20px 10px;

        &:visited {
            color: $color_black;
        }

        @include media('<=tablet') {
            padding: 10px;
            font-size: 14px;
            white-space: pre;
        }

        &:after {
            @include media('<=tablet') {
                width: 21px;
                height: 21px;
                min-width: 21px;
                margin-left: 8px;
            }
        }

    }
}

