/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Gap between columns (all CSS units allowed) [Example: 1.5rem]
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/column-gap
 */
/**
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/column-rule
 */
.cs-paragraph {
  column-gap: 6rem;
  column-rule: 1px solid #dcdcdc;
  margin: 0 0 3rem; }
  @media (min-width: 48em) {
    .cs-paragraph {
      margin: 0 0 4rem; } }
  .cs-paragraph--skip-spacing {
    margin: 0; }
  .cs-paragraph img {
    height: auto; }
  .cs-paragraph h6 {
    font-size: 2.1rem;
    text-transform: none;
    line-height: 38px;
    font-weight: 400;
    color: #000; }
    .cs-paragraph h6 strong {
      font-weight: 400; }
  .cs-paragraph h3,
  .cs-paragraph h4 {
    text-transform: none; }
  @media (min-width: 48em) {
    .cs-paragraph--cols-2, .cs-paragraph--cols-3, .cs-paragraph--cols-4 {
      column-count: 2; }
      .cs-paragraph--cols-2 li, .cs-paragraph--cols-3 li, .cs-paragraph--cols-4 li {
        width: 100%; }
      .cs-paragraph--cols-2 img, .cs-paragraph--cols-3 img, .cs-paragraph--cols-4 img {
        display: block;
        max-width: 100%; } }
  @media (min-width: 64em) {
    .cs-paragraph--cols-3, .cs-paragraph--cols-4 {
      column-count: 3; } }
  @media (min-width: 80em) {
    .cs-paragraph--cols-4 {
      column-count: 4; } }
  .cs-paragraph--mode-optimal {
    max-width: 40em;
    margin-left: auto;
    margin-right: auto;
    column-count: initial; }

.cms-page-view .main .cs-paragraph p,
.cms-page-view .main .cs-paragraph h6 {
  margin: 0 0 25px; }

.ips-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; }
  @media (min-width: 48em) {
    .ips-wrapper {
      flex-direction: row;
      flex-wrap: nowrap; } }
  .ips-wrapper .content h2 {
    font-family: "Red Hat Display";
    font-size: 1.8rem;
    text-transform: capitalize;
    margin-bottom: 20px;
    font-weight: bold;
    line-height: 26px; }
  .ips-wrapper .content p {
    font-size: 1.6rem;
    color: #3E3E3E;
    font-weight: lighter;
    line-height: 24px; }

.ips-icon {
  display: block;
  min-height: 50px;
  height: 100%;
  width: auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }
  .ips-icon--height {
    background-image: url("../images/icons/height.svg"); }
  .ips-icon--weight {
    background-image: url("../images/icons/weight.svg"); }
  .ips-icon--users {
    background-image: url("../images/icons/users.svg"); }
  .ips-icon--duration {
    background-image: url("../images/icons/duration.svg"); }
  .ips-icon > span {
    display: none; }

.ips-section-wrapper {
  margin-left: -30px;
  margin-right: -30px; }

.js-ips-section {
  padding: 0 6px; }
  @media (min-width: 40em) {
    .js-ips-section {
      padding: 0 10px; } }
  @media (min-width: 48em) {
    .js-ips-section {
      padding: 0 15px; } }
  .js-ips-section .ips-section-inner {
    background-color: #F4F4F4;
    text-align: center;
    padding: 15px 10px;
    height: 100%; }
    @media (min-width: 48em) {
      .js-ips-section .ips-section-inner {
        padding: 15px 20px; } }
  .js-ips-section .title {
    padding: 0 5px;
    margin-bottom: 17px; }
    .js-ips-section .title h4 {
      font-family: "Red Hat Display";
      font-size: 1.8rem;
      text-transform: capitalize;
      margin-bottom: 20px;
      font-weight: bold; }
    .js-ips-section .title span {
      font-size: 1.6rem;
      color: #3E3E3E;
      font-weight: lighter;
      line-height: 24px; }
  .js-ips-section .options {
    list-style: none;
    padding: 0;
    margin: 0; }

.ips-item {
  text-align: center;
  min-height: 40px;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-family: "Red Hat Display";
  font-weight: bold;
  padding: 9px;
  max-width: 225px;
  width: 100%;
  display: block;
  border: 2px solid #000;
  border-radius: 50px;
  margin: 0 auto 1rem;
  cursor: pointer; }
  .ips-item.selected, .ips-item:hover {
    background-color: #FF4604;
    border-color: #FF4604;
    color: #ffffff; }

.hide-recommendations {
  display: none; }

.ips-wrapper .content {
  padding-right: 15px; }

.ips-section-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  @media (min-width: 48em) {
    .ips-section-wrapper {
      flex-direction: row;
      flex-wrap: nowrap; } }

.ips-section-wrapper > div {
  width: 100%;
  width: calc(100%/2);
  margin-bottom: 15px; }
  @media (min-width: 48em) {
    .ips-section-wrapper > div {
      width: calc(100%/4);
      margin-bottom: 0; } }

.js-ips-products-list {
  display: flex;
  flex-direction: column;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 30px;
  margin-bottom: 15px; }
  @media (min-width: 40em) {
    .js-ips-products-list {
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 45px;
      margin-bottom: 55px; } }
  @media (min-width: 48em) {
    .js-ips-products-list {
      flex-direction: row;
      flex-wrap: nowrap; } }

.js-ips-products-list ul {
  list-style: none;
  padding: 0; }

.js-ips-products {
  margin-top: 55px;
  margin-left: -15px;
  margin-right: -15px; }
  .js-ips-products h2 {
    font-size: 2.8rem;
    font-family: "Red Hat Display";
    text-transform: none;
    margin-bottom: 20px;
    font-weight: bold;
    line-height: 28px; }
  .js-ips-products > p {
    font-size: 1.6rem;
    color: #3E3E3E;
    font-weight: lighter;
    line-height: 24px; }
  .js-ips-products .ips-product.show {
    border: 2px solid #FF4604; }
    .js-ips-products .ips-product.show a {
      font-weight: bold;
      color: #000; }
  .js-ips-products .ips-product a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #575757;
    letter-spacing: 0.5px;
    text-decoration: underline; }
    @media (min-width: 48em) {
      .js-ips-products .ips-product a {
        flex-direction: row; } }
  .js-ips-products .ips-product img {
    margin-right: 20px;
    max-width: 100px; }

.ips-product-container {
  height: 100%;
  padding: 0 6px;
  width: 100%;
  margin-bottom: 15px; }
  @media (min-width: 40em) {
    .ips-product-container {
      padding: 0 10px;
      width: calc(100%/3);
      margin-bottom: 0; } }
  @media (min-width: 48em) {
    .ips-product-container {
      padding: 0 15px; } }
  .ips-product-container li {
    margin-bottom: 30px; }
  @media (max-width: 39.99em) {
    .ips-product-container ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
      .ips-product-container ul li {
        width: calc(100%/2);
        display: block;
        padding: 0 6px;
        text-align: center; } }
