/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-pwa-notification._show {
  position: fixed;
  bottom: 0;
  top: auto;
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-y: visible; }
  .cs-pwa-notification._show .modal-inner-wrap {
    width: 100%;
    margin: 0;
    background: #ffffff;
    box-shadow: 0 -0.3rem 0.5rem 0 rgba(0, 0, 0, 0.15); }

.cs-pwa-notification__title {
  margin: 0;
  padding: 2rem;
  font-size: 1.8rem; }

.cs-pwa-notification__content {
  padding: 0.5rem 2rem 1rem; }

.cs-pwa-notification__footer {
  padding: 0.5rem 2rem 2rem;
  display: flex;
  justify-content: flex-end; }

.cs-pwa-notification__button {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  position: relative;
  line-height: 1.275em;
  margin: 0;
  text-transform: uppercase;
  border-radius: 25px;
  font-family: "Red Hat Display";
  padding: 8px 25px;
  font-size: 1.2rem;
  box-sizing: border-box;
  transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
  vertical-align: middle;
  z-index: 5;
  will-change: transform, background-color, border-color, fill, color; }
  .cs-pwa-notification__button.medium {
    padding: 11px 25px;
    font-size: 1.4rem; }
  .cs-pwa-notification__button.large {
    padding: 13px 25px;
    font-size: 1.6rem; }
  .cs-pwa-notification__button::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0; }
  .cs-pwa-notification__button, .cs-pwa-notification__button:visited {
    cursor: pointer;
    border-radius: 25px;
    outline-offset: 1px;
    text-decoration: none;
    box-shadow: none;
    border: 1px solid transparent;
    color: #ffffff;
    background-color: #FF4604; }
    .cs-pwa-notification__button *, .cs-pwa-notification__button:visited * {
      color: #ffffff;
      fill: #ffffff; }
  .cs-pwa-notification__button:hover:not([disabled]), .cs-pwa-notification__button:focus:not([disabled]), .cs-pwa-notification__button:active:not([disabled]) {
    box-shadow: none;
    outline: none;
    text-decoration: none;
    border: 1px solid transparent;
    color: #ffffff;
    background-color: #FF7442; }
    .cs-pwa-notification__button:hover:not([disabled]) *, .cs-pwa-notification__button:focus:not([disabled]) *, .cs-pwa-notification__button:active:not([disabled]) * {
      color: #ffffff;
      fill: #ffffff; }
  .cs-pwa-notification__button[disabled] {
    opacity: 0.65;
    cursor: auto; }
  .cs-pwa-notification__button span {
    margin-top: 0;
    z-index: 1;
    font-weight: 700;
    vertical-align: baseline;
    display: inline-block;
    line-height: 1.275em;
    letter-spacing: normal; }

.cs-pwa-notification + .modals-overlay {
  display: none; }
