/**
 * Footer component customization.
 * @see /Magento_Theme/templates/footer/columns.phtml for templates.
 */
@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/visually-hidden';
@import 'components/pseudoicon/mixin';
@import 'components/typography/mixin';

$footer_text-color: $color_text-200 !default;
$footer_background: #000 url("../images/footer-bg-image.jpg") no-repeat !default;
$footer_edge-gutter: $page_edge-gutter !default;

$footer_section-edge-gutter: $page_edge-gutter !default;
$footer_section-vertical-offset: 2em !default;

$footer_section-separator-border: 1px solid $color_gray !default;

$footer_section-title-headline-level: $font-size_headline-4 !default;
$footer_section-title-padding: $footer_section-vertical-offset
    $footer_section-edge-gutter 0.7em !default;
$footer_section-title-color: $color_white !default;
$footer_section-title-font-size: 1.4rem !default;
$footer_section-title-transform: uppercase !default;

$footer_section-pseudoicon-include: true !default;

$footer_promo-display-on-mobile: false !default;
$footer_logo-display: block !default;
$footer_logo-width: 13.5em !default;
$footer_logo-height: 2.3em !default;

.#{$ns}footer {
    $root: &;
    font-size: 1rem;
    color: $footer_text-color;
    background: $footer_background;
    background-size: cover;
    overflow: hidden;
    padding: 0 0 2rem;

    @include media('>=tablet') {
      background-position-x: 41vw;
        padding: 0 $footer_edge-gutter;
    }

    &__content {
        padding: 0;

        @include media('>=tablet') {
            display: flex;
            flex-wrap: wrap;
            padding-top: 3.2rem;
            padding-bottom: 2.5rem;
        }

        @include media('>=laptop') {
            flex-wrap: nowrap;
        }
    }

    &__item {
        @include media('>=laptop') {
            flex: 1;
        }

        &--promo {
            @if $footer_promo-display-on-mobile == false {
                @include media('<tablet') {
                    display: none;
                }
            }

            flex-basis: 100%;

            @include media('<laptop') {
                margin-bottom: 1.5rem;
            }

            @include media('>=laptop') {
                flex-basis: auto;
                flex-grow: 1;
            }
        }

        &--links {
            @include media('>=tablet') {
                display: flex;
                flex-basis: auto;
                flex-grow: 1;
            }

            @include media('<tablet') {
                display:flex;
                flex-direction: row;
                flex-wrap: nowrap;
                background: $color_black;
                padding: 20px 10px 30px;

                .cs-footer__section  {
                    flex: 1 1 50%;
                    padding-left: 15px;
                }
            }

        }

        &--extras {
            @include media('>=tablet') {
                display: flex;
                justify-content: flex-end;
                flex-basis: 25%;
                flex-grow: 1.4;
            }

            @include media('<=tablet') {
                padding: 17px 20px 10px;
            }
        }
    }

    &__section {
        @include media('>=tablet') {
            flex: 1;
        }

        &--newsletter {
            display: flex;
            flex-direction: column;

            .top-links__phone-icon {
                order: 1;
                letter-spacing: 1px;
                    background: url(../images/icons/telephone66.svg) no-repeat;
                    background-size: 19px;
                    background-position: left 12px;
                    font-size: 24px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: #ffffff;
                    line-height: 28px;
                    padding: 8px 0 0 28px;

                    @include media('<=phoneLg') {
                        font-size: 18px;
                        background-size: 16px;
                        background-position: left 13px;
                    }
            }


            .cs-footer__section-title {
                order: 2;
            }
            .cs-newsletter {
                order: 3;

                p {
                    font-size: $font-size_paragraph;

                    @include media('<=phoneLg') {
                        font-size: 1.2rem;
                    }
                }

            }

            @include media('>=tablet') {
                flex-grow: 2;
                max-width: 615px;
            }
        }

        &--socials {
            @include media('>=laptop') {
                margin-top: 4rem;
            }
        }

        &--stores-switcher {
            @include media('>=laptop') {
                margin-top: 4rem;
                max-width: 20rem;
            }
        }


    }

    &__section-title,
    [data-role='title'] {
        @if $footer_section-title-headline-level != 'none' {
            @include headline($level: $footer_section-title-headline-level);
        }
        position: relative;
        margin: 25px 0 0;
        padding: $footer_section-title-padding;
        color: $footer_section-title-color;
        font-size: $footer_section-title-font-size;
        font-weight: 600;
        text-transform: $footer_section-title-transform;
        letter-spacing: 0.3px;
        padding-left: 0;
        padding-top: 0;
        padding-right: 0;

        @include media('<tablet') {
            border-top: none;

            #{$root}__section[data-mage-init] &,
            #{$root}__section[data-collapsible] & {
                cursor: pointer;

                @if $footer_section-pseudoicon-include {
                    @include pseudoicon();
                }
            }

            #{$root}__section--socials & {
                @include visually-hidden();
            }
        }

         @include media('<=phoneLg') {
            font-size: 1.2rem;
            margin: 20px 0 0;
         }
    }

    &__section-content,
    [data-role='content'] {
        padding: 0 $footer_section-edge-gutter $footer_section-edge-gutter;
        font-size: 1.2em;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        @include media('<tablet') {
            #{$root}__section[data-mage-init] &,
            #{$root}__section[data-collapsible] & {
                display: none;
            }
        }
    }

    &__shop-logo {
        display: $footer_logo-display;
        width: $footer_logo-width;
        height: $footer_logo-height;
        min-width: $footer_logo-width;
        max-width: 100%;
        max-height: 100%;
    }
}

.cs-container {
    &--footer {
        max-width: none;
    }
}
