@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/reset-list-style';

$footer-links_list-item-margin: 0 !default;

$footer-links_link-font-size: 14px !default;
$footer-links_link-color: $color_white !default;
$footer-links_link-hover-color: $color_white !default;
$footer-links_link-hover-decoration: underline !default;
$footer-links_link-padding-mobile: 0 0 4px !default;
$footer-links_link-padding-desktop: $footer-links_link-padding-mobile !default;


.#{$ns}footer-links {
    $root: &;

    @include reset-list-style();

    &-header {
        font-size: 14px;
        color: $color_white;
        letter-spacing: 0.5px;

        @include media('<=phoneLg') {
            font-size: 12px;
        }
    }

    &__item {
        margin: $footer-links_list-item-margin;
        font-size: 14px;
        font-weight: normal;
        color: $footer-links_link-color;

        @include media('<=phoneLg') {
            font-size: 12px;
            font-weight: normal;
            margin-bottom: 1px;
        }
    }

    a,
    a:visited {
        display: block;
        padding: $footer-links_link-padding-mobile;
        font-size: $footer-links_link-font-size;
        color: $footer-links_link-color;
        font-weight: normal;

        @include media('<=phoneLg') {
            font-size: 12px;
        }

        &:hover {
            color: $footer-links_link-hover-color;
            text-decoration: $footer-links_link-hover-decoration;
        }

        @include media('>=tablet') {
            padding: $footer-links_link-padding-desktop;
            display: inline-block;
        }
    }
}
