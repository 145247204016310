@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/visually-hidden';

@import 'components/field/mixin';
@import 'components/button/mixin';

$header-search_height: 2.875em !default;
$header-search_font-size: 1.2rem !default;

// Width of the search field. Works only for `$global_header-searchbox-as-layer-breakpoint` breakpoint, in other case it's always 100%
$header-search-width-as-layer: 42.7vw !default;

$header-search_close-color: $color_text-400 !default;

$header-search_button-type: $button_default-type !default;
$header-search_button-width: 105px !default;
$header-search_button-border-radius: 0 !important !default;
$header-search_button-transform: $transform_skew-400 translateX(0.7rem) !default;

$header-search_icon-search-color: $color_text-200 !default;
$header-search_icon-search-color-hover: $color_text-200 !default;
$header-search_icon-loader-color: $color_text-200 !default;
$header-search_icon-transform: $transform_skew-400-revert translateX(-0.3rem) !default;

$header-search_button-container-transition: transform 0.5s
        $transition-easing_slowdown-900,
    opacity 0.15s linear !default;

$header-search_autocomplete-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.17) !default;
$header-search_autocomplete-background: $color_background-200 !default;

$header-search-border-radius_base: 25px;

.#{$ns}header-search {
    $root: &;
    width: 100%;
    position: relative;

    .filler {
        display: inline-block;
        width: 2px;
        height: 10px;
        background-color: transparent;
    }

    @include media('>=laptop') {
        max-width: 605px;
    }

    @if ($global_header-searchbox-as-layer-breakpoint) {
        @include media($global_header-searchbox-as-layer-breakpoint) {
            width: 100%;
            min-width: 35vw;
        }
    }

    &__field {
        position: relative;
        border-radius: $header-search-border-radius_base;
        @if ($global_header-searchbox-as-layer-breakpoint) {
            .active-popup & {
                &:before,
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &__input {
        @include field-input();
        padding: 1.2rem 1rem;
        background-image: url("../images/icons/search19.svg");
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: 20px center;
        font-size: $header-search_font-size;
        border-radius: $header-search-border-radius_base;
        position: relative;
        z-index: 210;
        margin: 0;
        padding-left: 43px;
        padding-right: 110px;
        -webkit-appearance: none;


        &:focus {
            + #{$root}__action {
                .ajax-loading & {
                    #{$root}__button-container--search {
                        transform: translateX(100%);
                        opacity: 0;
                    }

                    #{$root}__button-container--loader {
                        transform: translateX(0);
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__action {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 220;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top-right-radius: $header-search-border-radius_base;
        border-bottom-right-radius: $header-search-border-radius_base;
    }

    &__close-icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
        display: none;
        opacity: 0.6;
        transition: opacity 0.3s linear;

        &:hover {
            cursor: pointer;
            opacity: 1;
        }

        path {
            fill: $header-search_close-color;
        }
    }

    &__close-label {
        @include visually-hidden();
    }

    &__button-container {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        transition: $header-search_button-container-transition;

        &--search {
            transform: translateX(0);
        }

        &--loader {
            transform: translateX(100%);
        }
    }

    &__button {
        @include button(
            $type: $header-search_button-type,
            $icon_pos: 'left'
        );

        width: $header-search_button-width;
        height: 100%;
        overflow: hidden;
        position: relative;
        backface-visibility: hidden;
        border-radius: 0 !important;
        border-bottom-right-radius: $header-search-border-radius_base;
        border-top-right-radius: $header-search-border-radius_base;
        padding-right: 20px;

        &[disabled] {
            opacity: 0.7;
        }
    }

    &__button-icon {
        transform: $header-search_icon-transform;

        &--search {
            display: none;
        }

        &--loader {
            stroke: $header-search_icon-loader-color;
            width: 3.2rem;
            height: 3.2rem;
        }
    }

    &__button-label {
        text-transform: uppercase;
        font-size: 13px;
        margin-left: -4px;
    }

    &__autocomplete {
        width: 100vw;
        left: 0;
        right: 0;

        .old-price {
            margin-right: 10px;
        }

        @include media('<tablet') {
            max-height: calc(
                100vh - #{$global_header-height-mobile} - #{$field_select-line-height} -
                    7rem
            );
            overflow-y: auto;
        }

        @if ($global_header-searchbox-as-layer-breakpoint) {
            @include media($global_header-searchbox-as-layer-breakpoint) {
                margin-top: 0;
                left: 50%;
                transform: translateX(-50%);
                box-shadow: $header-search_autocomplete-box-shadow;
                border: 0;
                width: 100%;
            }
        }
    }
}
