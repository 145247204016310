@import 'config/variables';
@import 'config/grid';
@import '../button/button';
@import 'vendors/include-media';
@import 'utils/component-spacing';
@import 'utils/reset-list-style';
@import 'components/headline/mixin';

$category-links_title-color: $color_black;
$category-links_title-font-size: $font-size_headline-3;
$category-links_title-font-weight: bold;
$category-links_title-lines-enabled: false;
$category-links_title-lines-color: null;

$category-links_link-font-size: 1.4rem;
$category-links_link-color: $color_black;
$category-links_link-letter-spacing: 2px;
$category-links_link-bg: $color_light-gray;


.#{$ns}category-links {
    $root: &;

    &__item {
        margin-bottom: 35px;

        > a {
            display: block;
            text-align: center;
        }
    }

    &__image-placeholder-wrapper {
        position: relative;
        padding-bottom: 69%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
        }
    }

    &__headline {
        font-size: 2.1rem;
        color: $color_black;
        font-weight: bold;
        text-transform: none;
        text-align: center;
        padding: 10px 0;
        margin: 0;
        justify-content: center;
        @extend .icon__arrow-round-right;

        @include media('<=phoneLg') {
            font-size: 1.4rem;
            letter-spacing: 1.2px;
            &:after {
                overflow: visible;
                width: 22px;
                height: 22px;
            }
        }

    }

    &__main-widget {
        .cs-category-links__subcats {
            background: $category-links_link-bg;
            li {
                width: 50%;
                background: $category-links_link-bg;
                text-align: left;
                margin: 0;

                &:nth-child(even) {
                    a {
                        border-left: 1px solid #fff;
                    }
                }

                a {
                    border-top: 1px solid #fff;
                    display: block;
                    padding: 11px 10px 10px;
                    line-height: 20px;
                    height: 100%;
                    @include media('<=phoneLg') {
                        padding: 6px;
                    }
                }
            }
        }
    }

    &__subcats {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;

    }

    &__subcat {
        &.empty {
            span {
                display: block;
                border-top: 1px solid #fff;
                padding: 10px;
                min-height: 41px;
                line-height: 16px;
            }

            &:nth-child(even) {
                span {
                    border-left: 1px solid #fff;
                }
            }
        }
    }

    &__subcat-label {
        font-size: $category-links_link-font-size;
        color: $category-links_link-color;
        text-transform: uppercase;
        letter-spacing: $category-links_link-letter-spacing;
        font-family: $font_family-secondary;
        font-weight: bold;

        @include media('<=phoneLg') {
            font-size: 12px;
        }
    }

    &--no-subcats {
        margin-bottom: 1em;
    }

    &__landing-block {
        .cs-category-links__item {
            width: 100%;
        }

        .cs-category-links__subcat {
            text-align: center;

            @include media('<=phoneLg') {
                padding: 0;
                width: 100%;
                max-width: 100%;
                flex-basis: 100%;
            }
        }

        .cs-category-links__image-placeholder-wrapper {
            padding-bottom: 59%;
        }

        .cs-category-links__subcat-label {
            font-size: 2.0rem;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: none;
            padding: 10px 0;
            font-weight: bold;
            font-family: $font_family-primary;
            letter-spacing: 0;

            @include media('<phoneLg') {
                font-size: 1.8rem;
            }
        }

        .cs-category-links__content {
            @include media('>=phoneLg') {
                margin-right: -1rem;
                margin-left: -1rem;
            }
        }
    }
}
