/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Flex Grid -
 * Version: 0.3.5
 *
 * Simple grid built with flex box and sass.
 *
 * Matthew Simo - matthew.a.simo@gmail.com
 */
/**
 * Grid setup
 *
 * The grid will calculate dimensions based on these two variables:
 * $fg-columns will inform the grid loops how many columns there should be.
 * $fg-gutter will inform the grid loops how big eac column's gutters should be.
 *
 * The grid will name columns, rows, offsets based on these three variables:
 * $fg-class-row string used for the row class
 * $fg-class-col string used for the column class
 * $fg-class-off string used for the offset class
 */
/**
 * Break point namespace object
 *
 * Set the default namespace object with these defaults with the
 * understanding that you can pass in whatever you might require for your site.
 *
 * $fg-breakpoints is a Sass list with nested lists inside. Each sub list defines two things.
 * 1. The namespace for that breakpoint. (Required) (i.e. xs, sm, md, lg)
 * 2. The min-width measurement for the breakpoint for that namespace. (i.e. 48em, 62em, 75em)
 *
 * Note: These should be in the proper order (at least till libsass handles map keys properly).
 *
 * Note: If the measurement is left out then it will be skipped when generating
 * the grid and applied to global styles.
 *
 */
/**
 * Class Name Defaults
 *
 * Define class names for columns, rows and offsets in case compatibility with other
 * libraries is necessary.
/**
 * Calculate column size percentage
 */
/**
 * Spacing mixin to create uniform margin/padding
 */
/**
 * Row wrapper class, flex box parent.
 */
.row-flex {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem; }

.col-flex-xs, .col-flex-sm, .col-flex-md, .col-flex-lg, .col-flex-xs-1, .col-flex-xs-2, .col-flex-xs-3, .col-flex-xs-4, .col-flex-xs-5, .col-flex-xs-6, .col-flex-xs-7, .col-flex-xs-8, .col-flex-xs-9, .col-flex-xs-10, .col-flex-xs-11, .col-flex-xs-12, .col-flex-sm-1, .col-flex-sm-2, .col-flex-sm-3, .col-flex-sm-4, .col-flex-sm-5, .col-flex-sm-6, .col-flex-sm-7, .col-flex-sm-8, .col-flex-sm-9, .col-flex-sm-10, .col-flex-sm-11, .col-flex-sm-12, .col-flex-md-1, .col-flex-md-2, .col-flex-md-3, .col-flex-md-4, .col-flex-md-5, .col-flex-md-6, .col-flex-md-7, .col-flex-md-8, .col-flex-md-9, .col-flex-md-10, .col-flex-md-11, .col-flex-md-12, .col-flex-lg-1, .col-flex-lg-2, .col-flex-lg-3, .col-flex-lg-4, .col-flex-lg-5, .col-flex-lg-6, .col-flex-lg-7, .col-flex-lg-8, .col-flex-lg-9, .col-flex-lg-10, .col-flex-lg-11, .col-flex-lg-12 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 1px;
  padding-left: 1rem;
  padding-right: 1rem; }

.col-flex-xs, .col-flex-sm, .col-flex-md, .col-flex-lg {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%; }

/**
 * Generate a set of grid column classes using a namespace
 *
 * .col-flex-[namespace] for intelligent column division
 * .col-flex-[namespace]-[number] for a column that covers a specific number of columns (e.g. 1-12 by default)
 * .off-[namespace]-[number] for pushing a col a specific number of columns (e.g. 1-11 by default)
 * .off-[namespace]-reset for resetting a col's offset for that and larger namespaces
 */
/**
 * Build the grid in two steps, to help minimize file size
 * Step 1, for each namespace, create the grid-base
 * Step 2, for each namespace, wrap the col width/offset measurements in their breakpoint media query
 */
.col-flex-xs-1 {
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

.col-flex-xs-2 {
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

.col-flex-xs-3 {
  flex-basis: 25%;
  max-width: 25%; }

.col-flex-xs-4 {
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

.col-flex-xs-5 {
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

.col-flex-xs-6 {
  flex-basis: 50%;
  max-width: 50%; }

.col-flex-xs-7 {
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

.col-flex-xs-8 {
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

.col-flex-xs-9 {
  flex-basis: 75%;
  max-width: 75%; }

.col-flex-xs-10 {
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

.col-flex-xs-11 {
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

.col-flex-xs-12 {
  flex-basis: 100%;
  max-width: 100%; }

.off-xs-1 {
  margin-left: 8.33333%; }

.off-xs-2 {
  margin-left: 16.66667%; }

.off-xs-3 {
  margin-left: 25%; }

.off-xs-4 {
  margin-left: 33.33333%; }

.off-xs-5 {
  margin-left: 41.66667%; }

.off-xs-6 {
  margin-left: 50%; }

.off-xs-7 {
  margin-left: 58.33333%; }

.off-xs-8 {
  margin-left: 66.66667%; }

.off-xs-9 {
  margin-left: 75%; }

.off-xs-10 {
  margin-left: 83.33333%; }

.off-xs-11 {
  margin-left: 91.66667%; }

.off-xs-reset {
  margin-left: 0; }

@media only screen and (min-width: 768px) {
  .col-flex-sm-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-flex-sm-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-flex-sm-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-flex-sm-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-flex-sm-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-flex-sm-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-flex-sm-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-flex-sm-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-flex-sm-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-flex-sm-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-flex-sm-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-flex-sm-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .off-sm-1 {
    margin-left: 8.33333%; }
  .off-sm-2 {
    margin-left: 16.66667%; }
  .off-sm-3 {
    margin-left: 25%; }
  .off-sm-4 {
    margin-left: 33.33333%; }
  .off-sm-5 {
    margin-left: 41.66667%; }
  .off-sm-6 {
    margin-left: 50%; }
  .off-sm-7 {
    margin-left: 58.33333%; }
  .off-sm-8 {
    margin-left: 66.66667%; }
  .off-sm-9 {
    margin-left: 75%; }
  .off-sm-10 {
    margin-left: 83.33333%; }
  .off-sm-11 {
    margin-left: 91.66667%; }
  .off-sm-reset {
    margin-left: 0; } }

@media only screen and (min-width: 1024px) {
  .col-flex-md-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-flex-md-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-flex-md-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-flex-md-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-flex-md-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-flex-md-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-flex-md-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-flex-md-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-flex-md-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-flex-md-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-flex-md-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-flex-md-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .off-md-1 {
    margin-left: 8.33333%; }
  .off-md-2 {
    margin-left: 16.66667%; }
  .off-md-3 {
    margin-left: 25%; }
  .off-md-4 {
    margin-left: 33.33333%; }
  .off-md-5 {
    margin-left: 41.66667%; }
  .off-md-6 {
    margin-left: 50%; }
  .off-md-7 {
    margin-left: 58.33333%; }
  .off-md-8 {
    margin-left: 66.66667%; }
  .off-md-9 {
    margin-left: 75%; }
  .off-md-10 {
    margin-left: 83.33333%; }
  .off-md-11 {
    margin-left: 91.66667%; }
  .off-md-reset {
    margin-left: 0; } }

@media only screen and (min-width: 1280px) {
  .col-flex-lg-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-flex-lg-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-flex-lg-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-flex-lg-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-flex-lg-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-flex-lg-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-flex-lg-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-flex-lg-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-flex-lg-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-flex-lg-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-flex-lg-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-flex-lg-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .off-lg-1 {
    margin-left: 8.33333%; }
  .off-lg-2 {
    margin-left: 16.66667%; }
  .off-lg-3 {
    margin-left: 25%; }
  .off-lg-4 {
    margin-left: 33.33333%; }
  .off-lg-5 {
    margin-left: 41.66667%; }
  .off-lg-6 {
    margin-left: 50%; }
  .off-lg-7 {
    margin-left: 58.33333%; }
  .off-lg-8 {
    margin-left: 66.66667%; }
  .off-lg-9 {
    margin-left: 75%; }
  .off-lg-10 {
    margin-left: 83.33333%; }
  .off-lg-11 {
    margin-left: 91.66667%; }
  .off-lg-reset {
    margin-left: 0; } }

/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/***
 * Remove browser's margins, paddings and list style rules
 */
.cs-button {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  position: relative;
  line-height: 1.275em;
  margin: 0;
  text-transform: uppercase;
  border-radius: 25px;
  font-family: "Red Hat Display";
  padding: 8px 25px;
  font-size: 1.2rem;
  box-sizing: border-box;
  transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
  vertical-align: middle;
  z-index: 5;
  will-change: transform, background-color, border-color, fill, color; }
  .cs-button.medium {
    padding: 11px 25px;
    font-size: 1.4rem; }
  .cs-button.large {
    padding: 13px 25px;
    font-size: 1.6rem; }
  .cs-button::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0; }
  .cs-button, .cs-button:visited {
    cursor: pointer;
    border-radius: 25px;
    outline-offset: 1px;
    text-decoration: none;
    box-shadow: none;
    border: 1px solid transparent;
    color: #ffffff;
    background-color: #FF4604; }
    .cs-button *, .cs-button:visited * {
      color: #ffffff;
      fill: #ffffff; }
  .cs-button:hover:not([disabled]), .cs-button:focus:not([disabled]), .cs-button:active:not([disabled]) {
    box-shadow: none;
    outline: none;
    text-decoration: none;
    border: 1px solid transparent;
    color: #ffffff;
    background-color: #FF7442; }
    .cs-button:hover:not([disabled]) *, .cs-button:focus:not([disabled]) *, .cs-button:active:not([disabled]) * {
      color: #ffffff;
      fill: #ffffff; }
  .cs-button[disabled] {
    opacity: 0.65;
    cursor: auto; }
  .cs-button__span {
    margin-top: 0;
    z-index: 1;
    font-weight: 700;
    vertical-align: baseline;
    display: inline-block;
    line-height: 1.275em;
    letter-spacing: normal;
    padding-right: 4em; }
  .cs-button__icon {
    max-width: 1em;
    max-height: 1.4rem;
    z-index: 2;
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    margin-right: 0;
    right: 1em; }

.cs-button-component {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  position: relative;
  line-height: 1.275em;
  margin: 0;
  text-transform: uppercase;
  border-radius: 25px;
  font-family: "Red Hat Display";
  padding: 8px 25px;
  font-size: 1.2rem;
  box-sizing: border-box;
  transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
  vertical-align: middle;
  z-index: 5;
  will-change: transform, background-color, border-color, fill, color;
  margin: 0 0 3rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%); }
  .cs-button-component.medium {
    padding: 11px 25px;
    font-size: 1.4rem; }
  .cs-button-component.large {
    padding: 13px 25px;
    font-size: 1.6rem; }
  .cs-button-component::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0; }
  .cs-button-component, .cs-button-component:visited {
    cursor: pointer;
    border-radius: 25px;
    outline-offset: 1px;
    text-decoration: none;
    box-shadow: none;
    border: 1px solid transparent;
    color: #ffffff;
    background-color: #FF4604; }
    .cs-button-component *, .cs-button-component:visited * {
      color: #ffffff;
      fill: #ffffff; }
  .cs-button-component:hover:not([disabled]), .cs-button-component:focus:not([disabled]), .cs-button-component:active:not([disabled]) {
    box-shadow: none;
    outline: none;
    text-decoration: none;
    border: 1px solid transparent;
    color: #ffffff;
    background-color: #FF7442; }
    .cs-button-component:hover:not([disabled]) *, .cs-button-component:focus:not([disabled]) *, .cs-button-component:active:not([disabled]) * {
      color: #ffffff;
      fill: #ffffff; }
  .cs-button-component[disabled] {
    opacity: 0.65;
    cursor: auto; }
  @media (min-width: 48em) {
    .cs-button-component {
      margin: 0 0 4rem; } }
  .cs-button-component--skip-spacing {
    margin: 0; }
  .cs-button-component__span {
    margin-top: 0;
    z-index: 1;
    font-weight: 700;
    vertical-align: baseline;
    display: inline-block;
    line-height: 1.275em;
    letter-spacing: normal;
    padding-right: 4em; }
  .cs-button-component__icon {
    max-width: 1em;
    max-height: 1.4rem;
    z-index: 2;
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    margin-right: 0;
    right: 1em; }
  .cs-button-component__amount:before {
    content: '('; }
  .cs-button-component__amount:after {
    content: ')'; }

.button.secondary {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  position: relative;
  line-height: 1.275em;
  margin: 0;
  text-transform: uppercase;
  border-radius: 25px;
  font-family: "Red Hat Display";
  padding: 8px 25px;
  font-size: 1.2rem;
  box-sizing: border-box;
  transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
  vertical-align: middle;
  z-index: 5;
  will-change: transform, background-color, border-color, fill, color; }
  .button.secondary.medium {
    padding: 11px 25px;
    font-size: 1.4rem; }
  .button.secondary.large {
    padding: 13px 25px;
    font-size: 1.6rem; }
  .button.secondary::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0; }
  .button.secondary, .button.secondary:visited {
    cursor: pointer;
    border-radius: 25px;
    outline-offset: 1px;
    text-decoration: none;
    box-shadow: none;
    border: 1px solid transparent;
    color: #ffffff;
    background-color: #000; }
    .button.secondary *, .button.secondary:visited * {
      color: #ffffff;
      fill: #FF4604; }
  .button.secondary:hover:not([disabled]), .button.secondary:focus:not([disabled]), .button.secondary:active:not([disabled]) {
    box-shadow: none;
    outline: none;
    text-decoration: none;
    border: 1px solid transparent;
    color: #ffffff;
    background-color: #3E3E3E; }
    .button.secondary:hover:not([disabled]) *, .button.secondary:focus:not([disabled]) *, .button.secondary:active:not([disabled]) * {
      color: #ffffff;
      fill: #ffffff; }
  .button.secondary[disabled] {
    opacity: 0.65;
    cursor: auto; }

.button.primary {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  position: relative;
  line-height: 1.275em;
  margin: 0;
  text-transform: uppercase;
  border-radius: 25px;
  font-family: "Red Hat Display";
  padding: 8px 25px;
  font-size: 1.2rem;
  box-sizing: border-box;
  transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
  vertical-align: middle;
  z-index: 5;
  will-change: transform, background-color, border-color, fill, color;
  font-size: 1.2rem;
  line-height: 16px; }
  .button.primary.medium {
    padding: 11px 25px;
    font-size: 1.4rem; }
  .button.primary.large {
    padding: 13px 25px;
    font-size: 1.6rem; }
  .button.primary::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0; }
  .button.primary, .button.primary:visited {
    cursor: pointer;
    border-radius: 25px;
    outline-offset: 1px;
    text-decoration: none;
    box-shadow: none;
    border: 1px solid transparent;
    color: #ffffff;
    background-color: #FF4604; }
    .button.primary *, .button.primary:visited * {
      color: #ffffff;
      fill: #ffffff; }
  .button.primary:hover:not([disabled]), .button.primary:focus:not([disabled]), .button.primary:active:not([disabled]) {
    box-shadow: none;
    outline: none;
    text-decoration: none;
    border: 1px solid transparent;
    color: #ffffff;
    background-color: #FF7442; }
    .button.primary:hover:not([disabled]) *, .button.primary:focus:not([disabled]) *, .button.primary:active:not([disabled]) * {
      color: #ffffff;
      fill: #ffffff; }
  .button.primary[disabled] {
    opacity: 0.65;
    cursor: auto; }

.icon__arrow-round-right, .cs-category-links__headline {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center; }
  .icon__arrow-round-right:after, .cs-category-links__headline:after {
    content: '';
    display: block;
    background: url("../images/icons/arrow@1x.svg") no-repeat;
    background-size: contain;
    background-position: center;
    width: 27px;
    height: 27px;
    margin-left: 15px; }

.icon__arrow-round-left {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center; }
  .icon__arrow-round-left:before {
    content: '';
    display: block;
    background: url("../images/icons/arrowleft@1x.svg") no-repeat;
    background-size: contain;
    background-position: center;
    width: 27px;
    height: 27px;
    margin-right: 15px; }

.links-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }
  @media (max-width: 48em) {
    .links-list {
      flex-wrap: wrap;
      margin-bottom: 20px; } }
  .links-list__item {
    flex: 1 1; }
    @media (max-width: 48em) {
      .links-list__item {
        flex: 1 1 50%;
        margin: 0; } }
  .links-list__link {
    color: #000;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    letter-spacing: 0.4px;
    padding: 20px 10px; }
    .links-list__link:visited {
      color: #000; }
    @media (max-width: 48em) {
      .links-list__link {
        padding: 10px;
        font-size: 14px;
        white-space: pre; } }
    @media (max-width: 48em) {
      .links-list__link:after {
        width: 21px;
        height: 21px;
        min-width: 21px;
        margin-left: 8px; } }

/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Remove browser's margins, paddings and list style rules
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-category-links__item {
  margin-bottom: 35px; }
  .cs-category-links__item > a {
    display: block;
    text-align: center; }

.cs-category-links__image-placeholder-wrapper {
  position: relative;
  padding-bottom: 69%; }
  .cs-category-links__image-placeholder-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto; }

.cs-category-links__headline {
  font-size: 2.1rem;
  color: #000;
  font-weight: bold;
  text-transform: none;
  text-align: center;
  padding: 10px 0;
  margin: 0;
  justify-content: center; }
  @media (max-width: 40em) {
    .cs-category-links__headline {
      font-size: 1.4rem;
      letter-spacing: 1.2px; }
      .cs-category-links__headline:after {
        overflow: visible;
        width: 22px;
        height: 22px; } }

.cs-category-links__main-widget .cs-category-links__subcats {
  background: #F4F4F4; }
  .cs-category-links__main-widget .cs-category-links__subcats li {
    width: 50%;
    background: #F4F4F4;
    text-align: left;
    margin: 0; }
    .cs-category-links__main-widget .cs-category-links__subcats li:nth-child(even) a {
      border-left: 1px solid #fff; }
    .cs-category-links__main-widget .cs-category-links__subcats li a {
      border-top: 1px solid #fff;
      display: block;
      padding: 11px 10px 10px;
      line-height: 20px;
      height: 100%; }
      @media (max-width: 40em) {
        .cs-category-links__main-widget .cs-category-links__subcats li a {
          padding: 6px; } }

.cs-category-links__subcats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0; }

.cs-category-links__subcat.empty span {
  display: block;
  border-top: 1px solid #fff;
  padding: 10px;
  min-height: 41px;
  line-height: 16px; }

.cs-category-links__subcat.empty:nth-child(even) span {
  border-left: 1px solid #fff; }

.cs-category-links__subcat-label {
  font-size: 1.4rem;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "PT Sans Narrow";
  font-weight: bold; }
  @media (max-width: 40em) {
    .cs-category-links__subcat-label {
      font-size: 12px; } }

.cs-category-links--no-subcats {
  margin-bottom: 1em; }

.cs-category-links__landing-block .cs-category-links__item {
  width: 100%; }

.cs-category-links__landing-block .cs-category-links__subcat {
  text-align: center; }
  @media (max-width: 40em) {
    .cs-category-links__landing-block .cs-category-links__subcat {
      padding: 0;
      width: 100%;
      max-width: 100%;
      flex-basis: 100%; } }

.cs-category-links__landing-block .cs-category-links__image-placeholder-wrapper {
  padding-bottom: 59%; }

.cs-category-links__landing-block .cs-category-links__subcat-label {
  font-size: 2.0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  padding: 10px 0;
  font-weight: bold;
  font-family: "Red Hat Display";
  letter-spacing: 0; }
  @media (max-width: 39.99em) {
    .cs-category-links__landing-block .cs-category-links__subcat-label {
      font-size: 1.8rem; } }

@media (min-width: 40em) {
  .cs-category-links__landing-block .cs-category-links__content {
    margin-right: -1rem;
    margin-left: -1rem; } }
