/**
 * Footer component customization.
 * @see /Magento_Theme/templates/footer/columns.phtml for templates.
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-footer {
  font-size: 1rem;
  color: #ffffff;
  background: #000 url("../images/footer-bg-image.jpg") no-repeat;
  background-size: cover;
  overflow: hidden;
  padding: 0 0 2rem; }
  @media (min-width: 48em) {
    .cs-footer {
      background-position-x: 41vw;
      padding: 0 1.5rem; } }
  .cs-footer__content {
    padding: 0; }
    @media (min-width: 48em) {
      .cs-footer__content {
        display: flex;
        flex-wrap: wrap;
        padding-top: 3.2rem;
        padding-bottom: 2.5rem; } }
    @media (min-width: 64em) {
      .cs-footer__content {
        flex-wrap: nowrap; } }
  @media (min-width: 64em) {
    .cs-footer__item {
      flex: 1; } }
  .cs-footer__item--promo {
    flex-basis: 100%; }
    @media (max-width: 47.99em) {
      .cs-footer__item--promo {
        display: none; } }
    @media (max-width: 63.99em) {
      .cs-footer__item--promo {
        margin-bottom: 1.5rem; } }
    @media (min-width: 64em) {
      .cs-footer__item--promo {
        flex-basis: auto;
        flex-grow: 1; } }
  @media (min-width: 48em) {
    .cs-footer__item--links {
      display: flex;
      flex-basis: auto;
      flex-grow: 1; } }
  @media (max-width: 47.99em) {
    .cs-footer__item--links {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      background: #000;
      padding: 20px 10px 30px; }
      .cs-footer__item--links .cs-footer__section {
        flex: 1 1 50%;
        padding-left: 15px; } }
  @media (min-width: 48em) {
    .cs-footer__item--extras {
      display: flex;
      justify-content: flex-end;
      flex-basis: 25%;
      flex-grow: 1.4; } }
  @media (max-width: 48em) {
    .cs-footer__item--extras {
      padding: 17px 20px 10px; } }
  @media (min-width: 48em) {
    .cs-footer__section {
      flex: 1; } }
  .cs-footer__section--newsletter {
    display: flex;
    flex-direction: column; }
    .cs-footer__section--newsletter .top-links__phone-icon {
      order: 1;
      letter-spacing: 1px;
      background: url(../images/icons/telephone66.svg) no-repeat;
      background-size: 19px;
      background-position: left 12px;
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
      color: #ffffff;
      line-height: 28px;
      padding: 8px 0 0 28px; }
      @media (max-width: 40em) {
        .cs-footer__section--newsletter .top-links__phone-icon {
          font-size: 18px;
          background-size: 16px;
          background-position: left 13px; } }
    .cs-footer__section--newsletter .cs-footer__section-title {
      order: 2; }
    .cs-footer__section--newsletter .cs-newsletter {
      order: 3; }
      .cs-footer__section--newsletter .cs-newsletter p {
        font-size: 1.6rem; }
        @media (max-width: 40em) {
          .cs-footer__section--newsletter .cs-newsletter p {
            font-size: 1.2rem; } }
    @media (min-width: 48em) {
      .cs-footer__section--newsletter {
        flex-grow: 2;
        max-width: 615px; } }
  @media (min-width: 64em) {
    .cs-footer__section--socials {
      margin-top: 4rem; } }
  @media (min-width: 64em) {
    .cs-footer__section--stores-switcher {
      margin-top: 4rem;
      max-width: 20rem; } }
  .cs-footer__section-title,
  .cs-footer [data-role='title'] {
    font-family: "Red Hat Display", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Oxygen, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.6rem;
    color: #212121;
    font-weight: 700;
    text-transform: uppercase;
    margin: 1rem 0 2rem;
    position: relative;
    margin: 25px 0 0;
    padding: 2em 1.5rem 0.7em;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    padding-left: 0;
    padding-top: 0;
    padding-right: 0; }
    @media (max-width: 47.99em) {
      .cs-footer__section-title,
      .cs-footer [data-role='title'] {
        border-top: none; }
        .cs-footer__section[data-mage-init] .cs-footer__section-title,
        .cs-footer__section[data-collapsible] .cs-footer__section-title, .cs-footer__section[data-mage-init]
        .cs-footer [data-role='title'],
        .cs-footer__section[data-collapsible]
        .cs-footer [data-role='title'] {
          cursor: pointer;
          position: relative; }
          .cs-footer__section[data-mage-init] .cs-footer__section-title:before, .cs-footer__section[data-mage-init] .cs-footer__section-title:after,
          .cs-footer__section[data-collapsible] .cs-footer__section-title:before,
          .cs-footer__section[data-collapsible] .cs-footer__section-title:after, .cs-footer__section[data-mage-init]
          .cs-footer [data-role='title']:before, .cs-footer__section[data-mage-init]
          .cs-footer [data-role='title']:after,
          .cs-footer__section[data-collapsible]
          .cs-footer [data-role='title']:before,
          .cs-footer__section[data-collapsible]
          .cs-footer [data-role='title']:after {
            content: '';
            position: absolute;
            top: 50%;
            width: 1.2rem;
            height: 0.2rem;
            left: auto;
            background-color: #6E6E6E;
            transition: transform 0.3s ease-out, opacity 0.15s ease-out, background-color 0.15s linear;
            backface-visibility: hidden; }
          .cs-footer__section[data-mage-init] .cs-footer__section-title:before, .cs-footer__section[data-mage-init] .cs-footer__section-title:after,
          .cs-footer__section[data-collapsible] .cs-footer__section-title:before,
          .cs-footer__section[data-collapsible] .cs-footer__section-title:after, .cs-footer__section[data-mage-init]
          .cs-footer [data-role='title']:before, .cs-footer__section[data-mage-init]
          .cs-footer [data-role='title']:after,
          .cs-footer__section[data-collapsible]
          .cs-footer [data-role='title']:before,
          .cs-footer__section[data-collapsible]
          .cs-footer [data-role='title']:after {
            right: 1.5rem;
            transform: translate3d(0, -50%, 0) !important; }
          .cs-footer__section[data-mage-init] .cs-footer__section-title:after,
          .cs-footer__section[data-collapsible] .cs-footer__section-title:after, .cs-footer__section[data-mage-init]
          .cs-footer [data-role='title']:after,
          .cs-footer__section[data-collapsible]
          .cs-footer [data-role='title']:after {
            transform: translate3d(0, -50%, 0) rotate(90deg) !important; }
          .cs-footer__section[data-mage-init] .cs-footer__section-title[aria-expanded="true"]:before,
          .cs-footer__section[data-collapsible] .cs-footer__section-title[aria-expanded="true"]:before, .cs-footer__section[data-mage-init]
          .cs-footer [data-role='title'][aria-expanded="true"]:before,
          .cs-footer__section[data-collapsible]
          .cs-footer [data-role='title'][aria-expanded="true"]:before {
            transform: translate3d(0, -50%, 0) rotate(-90deg) !important;
            opacity: 0; }
          .cs-footer__section[data-mage-init] .cs-footer__section-title[aria-expanded="true"]:after,
          .cs-footer__section[data-collapsible] .cs-footer__section-title[aria-expanded="true"]:after, .cs-footer__section[data-mage-init]
          .cs-footer [data-role='title'][aria-expanded="true"]:after,
          .cs-footer__section[data-collapsible]
          .cs-footer [data-role='title'][aria-expanded="true"]:after {
            transform: translate3d(0, -50%, 0) rotate(0) !important; }
        .cs-footer__section--socials .cs-footer__section-title, .cs-footer__section--socials
        .cs-footer [data-role='title'] {
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
          white-space: nowrap;
          /* 1 */ } }
    @media (max-width: 40em) {
      .cs-footer__section-title,
      .cs-footer [data-role='title'] {
        font-size: 1.2rem;
        margin: 20px 0 0; } }
  .cs-footer__section-content,
  .cs-footer [data-role='content'] {
    padding: 0 1.5rem 1.5rem;
    font-size: 1.2em;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0; }
    @media (max-width: 47.99em) {
      .cs-footer__section[data-mage-init] .cs-footer__section-content,
      .cs-footer__section[data-collapsible] .cs-footer__section-content, .cs-footer__section[data-mage-init]
      .cs-footer [data-role='content'],
      .cs-footer__section[data-collapsible]
      .cs-footer [data-role='content'] {
        display: none; } }
  .cs-footer__shop-logo {
    display: block;
    width: 13.5em;
    height: 2.3em;
    min-width: 13.5em;
    max-width: 100%;
    max-height: 100%; }

.cs-container--footer {
  max-width: none; }
