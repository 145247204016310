/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
@media (min-width: 64em) and (max-width: 79.99em) {
  .cs-hide-laptop {
    display: none !important;
    /* stylelint-disable-line declaration-no-important */ } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-hide-tablet {
    display: none !important;
    /* stylelint-disable-line declaration-no-important */ } }

.cs-no-display {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */ }

.cs-aspect-ratio {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; }
  .cs-aspect-ratio__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Mixins for flexible containers declaration.
 */
/**
 * Defines page container. This mixin makes sure that the content has always equal padding
 * for mobile screens on both size of the device.
 */
/**
 * Negates parent container's padding and sets it own.
 * Somethimes this has to be done to make background even to other containers' content.
 */
/**
 * Defines container wrapper that makes sure contnet doesn't extend above certain width and is always centered.
 */
body.cms-no-route .cs-container__inner h1, body.cms-no-route .cs-container__reviews h1 {
  text-transform: none;
  font-family: 28px;
  line-height: 34px;
  margin-bottom: 25px; }

body.cms-no-route .cs-container__inner h2, body.cms-no-route .cs-container__reviews h2, body.cms-no-route .cs-container__inner h3, body.cms-no-route .cs-container__reviews h3 {
  font-size: 18px;
  text-transform: none; }

body.cms-no-route .cs-container__inner ul.buttons-set, body.cms-no-route .cs-container__reviews ul.buttons-set {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 20px 0 0; }
  body.cms-no-route .cs-container__inner ul.buttons-set button, body.cms-no-route .cs-container__reviews ul.buttons-set button {
    margin-right: 10px; }

body.cms-no-route .columns .column.main {
  padding: 45px 15px !important;
  max-width: 138rem;
  margin: 0 auto; }
  body.cms-no-route .columns .column.main h1 {
    text-transform: none;
    font-family: 28px;
    line-height: 34px;
    margin-bottom: 25px; }
  body.cms-no-route .columns .column.main h2, body.cms-no-route .columns .column.main h3 {
    font-size: 18px;
    text-transform: none; }
  body.cms-no-route .columns .column.main ul.buttons-set {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 20px 0 0; }
    body.cms-no-route .columns .column.main ul.buttons-set button {
      margin-right: 10px; }

.cs-container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box; }
  .cs-container__inner, .cs-container__reviews {
    max-width: 124rem;
    width: 100%;
    margin: 0 auto;
    position: relative; }
    .cs-container__inner:before, .cs-container__reviews:before, .cs-container__inner:after, .cs-container__reviews:after {
      content: '';
      display: table;
      clear: both; }
    .cs-container__inner--flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      @media (min-width: 48em) {
        .cs-container__inner--flex {
          flex-wrap: nowrap; } }
      .cs-container__inner--flex:after {
        display: none; }
  .cs-container__aside {
    width: 100%; }
    @media (min-width: 48em) {
      .cs-container__aside {
        width: 33%; } }
    @media (min-width: 64em) {
      .cs-container__aside {
        width: 24.8%; } }
    @media (min-width: 90em) {
      .cs-container__aside {
        width: 24.8%; } }
  .cs-container__main-column {
    width: 56%;
    position: relative; }
    @media (min-width: 48em) {
      .cs-container__main-column {
        width: 65%; } }
    @media (min-width: 64em) {
      .cs-container__main-column {
        width: 71.4%; } }
    @media (min-width: 90em) {
      .cs-container__main-column {
        width: 71.4%; } }
    @media (max-width: 47.99em) {
      .cs-container__main-column {
        max-width: 100% !important;
        width: 100% !important; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .page-product-bundle .cs-container__main-column {
    width: 100%;
    margin-right: 0; } }
    @media (min-width: 64em) {
      .cs-container__main-column--space-top {
        padding-top: 3rem; } }
  .cs-container__reviews {
    padding-bottom: 2rem; }
    @media (min-width: 48em) {
      .cs-container__reviews {
        background: #ffffff;
        margin-top: 3rem;
        padding: 0 2rem; } }
    @media (min-width: 90em) {
      .cs-container__reviews {
        padding: 2rem 0 0; } }
    .cs-container__reviews:after {
      content: '';
      display: table;
      clear: both; }
  .cs-container__cart-coupons {
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 64em) {
      .cs-container__cart-coupons {
        flex-wrap: nowrap;
        justify-content: space-between; } }
  .cs-container--contrast {
    background-color: #f0f0f0; }
    @media (min-width: 64em) {
      .cs-container--contrast {
        background-image: url("../images/background.png");
        background-size: cover;
        background-repeat: no-repeat; } }
  .cs-container--top-bar {
    background-color: transparent;
    max-width: none; }
  .cs-container--spacious {
    padding-top: 2rem;
    padding-bottom: 2rem; }
    @media (min-width: 64em) {
      .cs-container--spacious {
        padding-top: 3rem;
        padding-bottom: 3rem; } }
    @media (min-width: 48em) {
      .catalog-category-view .cs-container--spacious,
      .catalogsearch-result-index .cs-container--spacious {
        padding-top: 0; } }
  .cs-container--image-teaser-window-width, .cs-container--store-locator {
    padding-left: 0;
    padding-right: 0; }
  .cs-container--grid-teaser {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden; }
  .cs-container--hero-teaser {
    overflow: hidden;
    padding-left: 0;
    padding-right: 0; }
  .cs-container--noscroll {
    overflow: hidden; }
  @media (min-width: 64em) {
    .cs-container--pdp {
      padding-top: 0; } }
  .cs-container--usps .cs-container--headline {
    padding: 0; }
  .cs-container--create-account-page, .cs-container--login-page, .cs-container--success-page, .cs-container--logout-page {
    padding-top: 2rem;
    padding-bottom: 2rem; }
    @media (min-width: 64em) {
      .cs-container--create-account-page, .cs-container--login-page, .cs-container--success-page, .cs-container--logout-page {
        padding-top: 4rem;
        padding-bottom: 8rem; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-container--my-account-page {
    padding-left: 1.5rem;
    padding-right: 1.5rem; } }
  .cs-container--ontop {
    z-index: 301; }
  .cs-container--viewport-width {
    max-width: none; }
  @media (max-width: 47.99em) {
    .cs-container--page-pdp-details-aw_pq_ajax_container {
      padding: 0; } }
  .cs-container--cart-page .cs-container--products-carousel {
    padding-left: 0;
    padding-right: 0; }
  .cs-container--cart-page .cs-container--headline {
    padding-left: 0;
    padding-right: 0; }
  .cs-container--cms-teaser {
    margin-top: 2rem; }
  @supports (display: grid) and (grid-auto-flow: row) {
    .cs-container--grid-teaser {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      overflow: visible; } }

.contact-index-index .cs-breadcrumbs {
  padding-top: 15px;
  padding-bottom: 15px; }

.contact-index-index .cs-cms-contact-form {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin: 20px 0 80px; }
  @media (max-width: 47.99em) {
    .contact-index-index .cs-cms-contact-form {
      flex-direction: column;
      flex-wrap: wrap;
      margin: 20px 0 45px; } }

@media (max-width: 47.99em) {
  .contact-index-index .cs-container__inner, .contact-index-index .cs-container__reviews {
    padding: 0 15px; } }

@media (min-width: 48em) {
  .contact-index-index .cs-container__inner .contact-form, .contact-index-index .cs-container__reviews .contact-form {
    flex: 1 1 68%; } }

@media (max-width: 47.99em) {
  .contact-index-index .cs-container__inner .contact-form, .contact-index-index .cs-container__reviews .contact-form {
    order: 2; } }

.contact-index-index .cs-container__inner .contact-form fieldset.fieldset, .contact-index-index .cs-container__reviews .contact-form fieldset.fieldset {
  padding: 0;
  border: 0; }
  .contact-index-index .cs-container__inner .contact-form fieldset.fieldset h3, .contact-index-index .cs-container__reviews .contact-form fieldset.fieldset h3 {
    text-transform: none; }
    @media (max-width: 40em) {
      .contact-index-index .cs-container__inner .contact-form fieldset.fieldset h3, .contact-index-index .cs-container__reviews .contact-form fieldset.fieldset h3 {
        margin-bottom: 5px; } }
  .contact-index-index .cs-container__inner .contact-form fieldset.fieldset > span, .contact-index-index .cs-container__reviews .contact-form fieldset.fieldset > span {
    display: inline-block; }

.contact-index-index .cs-container__inner .contact-form .form .field + .field, .contact-index-index .cs-container__reviews .contact-form .form .field + .field {
  margin-top: 0; }

@media (min-width: 40.01em) {
  .contact-index-index .cs-container__inner .contact-form .flex-row, .contact-index-index .cs-container__reviews .contact-form .flex-row {
    display: flex;
    flex-direction: row; } }

@media (min-width: 40.01em) {
  .contact-index-index .cs-container__inner .contact-form .flex-row .field, .contact-index-index .cs-container__reviews .contact-form .flex-row .field {
    flex: 1 1 48%; } }

@media (min-width: 40.01em) {
  .contact-index-index .cs-container__inner .contact-form .flex-row .field:last-child, .contact-index-index .cs-container__reviews .contact-form .flex-row .field:last-child {
    margin-left: 20px; } }

.contact-index-index .cs-container__inner .contact-form .primary, .contact-index-index .cs-container__reviews .contact-form .primary {
  text-align: right; }

.contact-index-index .cs-container__inner .contact-address, .contact-index-index .cs-container__reviews .contact-address {
  background: #F4F4F4;
  padding: 20px 25px; }
  @media (max-width: 40em) {
    .contact-index-index .cs-container__inner .contact-address, .contact-index-index .cs-container__reviews .contact-address {
      padding: 10px 15px; } }
  @media (max-width: 47.99em) {
    .contact-index-index .cs-container__inner .contact-address, .contact-index-index .cs-container__reviews .contact-address {
      order: 1;
      width: 100%;
      margin-bottom: 35px; } }
  .contact-index-index .cs-container__inner .contact-address__item, .contact-index-index .cs-container__reviews .contact-address__item {
    margin-bottom: 20px;
    cursor: pointer; }
    @media (max-width: 40em) {
      .contact-index-index .cs-container__inner .contact-address__item, .contact-index-index .cs-container__reviews .contact-address__item {
        margin-bottom: 15px; } }
  .contact-index-index .cs-container__inner .contact-address p, .contact-index-index .cs-container__reviews .contact-address p {
    margin: 0; }
  @media (max-width: 40em) {
    .contact-index-index .cs-container__inner .contact-address p, .contact-index-index .cs-container__reviews .contact-address p,
    .contact-index-index .cs-container__inner .contact-address span,
    .contact-index-index .cs-container__reviews .contact-address span {
      font-size: 1.4rem; } }
  .contact-index-index .cs-container__inner .contact-address .icon-text, .contact-index-index .cs-container__reviews .contact-address .icon-text {
    display: block;
    padding-left: 25px;
    padding-right: 5px;
    position: relative; }
    .contact-index-index .cs-container__inner .contact-address .icon-text:before, .contact-index-index .cs-container__reviews .contact-address .icon-text:before {
      background-size: 10px 15px;
      background-repeat: no-repeat;
      background-position: center;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 5px;
      width: 15px;
      height: 15px; }
  .contact-index-index .cs-container__inner .contact-address .icon-location:before, .contact-index-index .cs-container__reviews .contact-address .icon-location:before {
    background-image: url("../images/icons/map-pin.svg"); }
  .contact-index-index .cs-container__inner .contact-address .icon-phone:before, .contact-index-index .cs-container__reviews .contact-address .icon-phone:before {
    background-image: url("../images/icons/phone-dark.svg");
    background-size: contain; }
  .contact-index-index .cs-container__inner .contact-address .icon-email:before, .contact-index-index .cs-container__reviews .contact-address .icon-email:before {
    background-image: url("../images/icons/email@1x.svg");
    background-size: contain;
    width: 17px;
    top: 6px; }
  @media (min-width: 48em) {
    .contact-index-index .cs-container__inner .contact-address, .contact-index-index .cs-container__reviews .contact-address {
      flex: 1 1 33%;
      margin-left: 20px; } }

@media (min-width: 48.01em) {
  .cms-page-view.page-layout-cms-full-width-narrow .columns .cs-container--brand-carousel,
  .cms-page-view.page-layout-cms-full-width .columns .cs-container--brand-carousel,
  .cms-page-view.page-layout-1colum .columns .cs-container--brand-carousel {
    margin-top: 45px; } }

@media (max-width: 48em) {
  .cms-page-view.page-layout-cms-full-width-narrow .columns .cs-container--brand-carousel,
  .cms-page-view.page-layout-cms-full-width .columns .cs-container--brand-carousel,
  .cms-page-view.page-layout-1colum .columns .cs-container--brand-carousel {
    margin-top: 35px; } }

.cms-page-view.page-layout-cms-full-width-narrow .cs-container--breadcrumbs .cs-breadcrumbs,
.cms-page-view.page-layout-cms-full-width .cs-container--breadcrumbs .cs-breadcrumbs,
.cms-page-view.page-layout-1colum .cs-container--breadcrumbs .cs-breadcrumbs {
  padding-top: 1.5rem; }
  @media (max-width: 47.99em) {
    .cms-page-view.page-layout-cms-full-width-narrow .cs-container--breadcrumbs .cs-breadcrumbs,
    .cms-page-view.page-layout-cms-full-width .cs-container--breadcrumbs .cs-breadcrumbs,
    .cms-page-view.page-layout-1colum .cs-container--breadcrumbs .cs-breadcrumbs {
      margin-bottom: 10px; } }

.cms-page-view.page-layout-cms-full-width-narrow .columns .column.main,
.cms-page-view.page-layout-cms-full-width .columns .column.main,
.cms-page-view.page-layout-1colum .columns .column.main {
  padding-top: 0; }
  @media (min-width: 48.01em) {
    .cms-page-view.page-layout-cms-full-width-narrow .columns .column.main,
    .cms-page-view.page-layout-cms-full-width .columns .column.main,
    .cms-page-view.page-layout-1colum .columns .column.main {
      padding-bottom: 30px; } }
  @media (max-width: 48em) {
    .cms-page-view.page-layout-cms-full-width-narrow .columns .column.main,
    .cms-page-view.page-layout-cms-full-width .columns .column.main,
    .cms-page-view.page-layout-1colum .columns .column.main {
      padding-bottom: 15px; } }

.cms-page-view.page-layout-cms-full-width-narrow .cs-container--breadcrumbs .cs-container__inner, .cms-page-view.page-layout-cms-full-width-narrow .cs-container--breadcrumbs .cs-container__reviews,
.cms-page-view.page-layout-cms-full-width-narrow .columns .cs-container:not(.cs-container--brand-carousel) .cs-container__inner,
.cms-page-view.page-layout-cms-full-width-narrow .columns .cs-container:not(.cs-container--brand-carousel) .cs-container__reviews {
  max-width: 103rem; }

/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
body,
html {
  height: 100%;
  max-width: 100vw; }

.no-scroll {
  position: fixed;
  width: 100vw;
  height: 100vh; }

.no-scroll-child {
  overflow-y: hidden !important; }

#maincontent {
  flex: 1 0 auto; }

.page-wrapper {
  display: block !important;
  min-height: 100%; }
  @media (min-width: 48em) {
    .page-wrapper {
      display: flex !important;
      flex-direction: column !important; } }

.columns .column.main {
  width: auto;
  padding: 2rem 0 0;
  max-width: 100%;
  min-height: 10rem; }

.page-layout-1column .product-info-wrapper {
  width: 100%; }
  @media (min-width: 48em) {
    .page-layout-1column .product-info-wrapper {
      position: absolute;
      right: 0;
      height: 100%;
      width: 40%; } }
  @media (min-width: 64em) {
    .page-layout-1column .product-info-wrapper {
      width: 30%; } }

@media (min-width: 48em) {
  .page-layout-2columns-left .sidebar-main,
  .page-layout-2columns-left .sidebar-additional {
    width: 22.5%;
    padding-right: 40px;
    border-right: 1px solid #f0f0f0; } }

.ie11 .page-wrapper {
  display: block !important; }

a {
  color: #FF4604; }
  a:visited {
    color: #FF4604; }
  a:hover {
    color: #FF7442; }

.gift-options-cart-item {
  display: none; }

@media (min-width: 48em) {
  .paypal-express-review .page-title-wrapper {
    padding: 0 1.5rem; } }

@media (max-width: 63.99em) {
  .paypal-review.view .cs-cart-item__price {
    padding-left: 0 !important; }
  .paypal-review.view .cs-cart-item__subtotal {
    padding-right: 0 !important; } }

@media (min-width: 48em) {
  .paypal-review.view {
    padding: 0 1.5rem; } }

.paypal-express-in-context-mini-cart-container {
  margin-top: 1rem; }

.paypal-review-discount {
  border-bottom: none; }

.amazon-button-container {
  max-width: 25rem;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  z-index: 303; }
  @media (min-width: 48em) {
    .amazon-button-container {
      max-width: 32rem; } }
  .amazon-button-container img {
    margin-left: auto;
    margin-right: auto; }
  .amazon-button-container__cell:nth-child(2) {
    position: absolute;
    min-width: 3rem;
    left: calc(100% + 0.6rem);
    top: 1.9rem; }
    @media (max-width: 29.99em) {
      .amazon-button-container__cell:nth-child(2) {
        left: calc(100% + 0.8rem); } }
  .amazon-button-container__cell .field-tooltip-content {
    width: 25rem;
    box-sizing: border-box;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.17);
    border: none;
    font-size: 1.3rem;
    padding: 0.8rem; }
    @media (min-width: 40em) {
      .amazon-button-container__cell .field-tooltip-content {
        width: 28rem; } }
    .amazon-button-container__cell .field-tooltip-content:before {
      border-right-color: #6E6E6E; }
    .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content,
    .cs-cart-summary .amazon-button-container__cell .field-tooltip-content {
      top: auto;
      bottom: 42px;
      left: -210px;
      right: auto; }
      @media (min-width: 48em) {
        .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content,
        .cs-cart-summary .amazon-button-container__cell .field-tooltip-content {
          left: -250px; } }
      @media (min-width: 64em) {
        .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content,
        .cs-cart-summary .amazon-button-container__cell .field-tooltip-content {
          left: -232px; } }
      .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:before, .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:after,
      .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:before,
      .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:after {
        top: calc(100% + 2rem);
        left: 210px;
        transform: rotate(-180deg); }
        @media (min-width: 48em) {
          .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:before, .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:after,
          .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:before,
          .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:after {
            bottom: -10px;
            top: 100%;
            left: 250px;
            transform: rotate(-90deg); } }
        @media (min-width: 64em) {
          .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:before, .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:after,
          .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:before,
          .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:after {
            left: 232px; } }
      .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:before,
      .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:before {
        left: 210px; }
        @media (max-width: 47.99em) {
          .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:before,
          .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:before {
            border-right-color: transparent; } }
        @media (min-width: 48em) {
          .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:before,
          .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:before {
            left: 251px;
            transform: rotate(-90deg); } }
        @media (min-width: 64em) {
          .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:before,
          .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:before {
            left: 233px; } }
      @media (min-width: 64em) {
        .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:after,
        .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:after {
          transform: rotate(-90deg); } }
    @media (min-width: 48em) {
      .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:before, .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:after {
        top: calc(100% + 2rem);
        transform: rotate(180deg); } }

.ie11 source {
  height: 0 !important; }
