@import 'config/variables';
@import 'utils/aspect-ratio';
@import 'vendors/include-media';

$navigation_font-size: 1rem !default;

$navigation_overlay-show: false !default;
// Box shadow effect applied for the flyout.
$navigation_flyout-box-shadow: 2px 2px 5px rgba($color_background-800, 0.15) !default;
// Flyout background color.
$navigation_flyout-background: $color_light-gray !default;
// Color of the link.
$navigation_link-color: $color_white !default;
// Color of the link in the flyout on hover.
$navigation_link-color-hover: $color_white !default;
$navigation_link-background-hover: $color_main__brand !default;
// Bottom border configuration for flyout category links.
$navigation_link-border: 1px solid $color_border-500 !default;
$navigation_lists-mark-parent-on-hover: true !default;

$subnavigation_link-color: $color_black !default;
$subnavigation_link-background-hover: $sidebar__grey !default;

.cs-container {
    &--navigation {
        background-color: $color_gray;
    }

    &__navigation-inner {
        margin: 10px auto 0;
    }
}

.cs-container--navigation {
    .#{$ns}navigation {
        $root: &;
        box-sizing: border-box;
        font-size: $navigation_font-size;

        &__list {
            list-style: none;
            padding: 0;
            margin: 0;

            &--main {
                position: relative;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
            }

            &--all-categories {
                width: 100%;
                columns: auto 1;
                column-gap: 0;
                flex: 1 0 auto;
            }

            &--level {
                &_1 {
                    width: 100%;
                    columns: auto 1;
                    column-gap: 0;
                    flex: 1 0 auto;
                }

                &_2 {
                    flex-wrap: wrap;
                }
            }

            &--hidden {
                display: none;

                & + .#{$ns}navigation__extras {
                    display: none;
                }
            }

            #{$root}__item--no-children {
                &:nth-child(2) {
                    padding-top: 1rem;
                }

                &:last-of-type {
                    padding-bottom: 1rem;
                }
            }
        }

        &__item {
            font-size: 0.9em;
            margin: 0;
            width: 100%;
            &--main {
                font-size: 1.4em;
                border-left: 1px solid $color_dark-gray;
                text-align: center;
                position: relative;

                &:first-of-type {
                    border-left: 0;
                }

                &:last-of-type {
                    border-right: 0;

                    .cs-navigation__flyout {
                        left: auto;
                        right: 0;
                    }
                }

                &:visited {
                    > a {
                        color: $navigation_link-color;
                        background-color: $navigation_link-background-hover;
                    }
                }

                &:hover {
                    > a {
                        color: $navigation_link-color;
                        background-color: $navigation_link-background-hover;
                    }
                }
            }

            &--all-categories {
                padding: 0;
                // Prevents element breaking because of column layout.
                page-break-inside: avoid; /* Theoretically FF 20+ */
                break-inside: avoid-column; /* IE 11 */
                display: table;
            }

            &--level {
                &_1 {
                    padding: 0;
                    // Prevents element breaking because of column layout.
                    page-break-inside: avoid; /* Theoretically FF 20+ */
                    break-inside: avoid-column; /* IE 11 */
                    display: table;
                }
            }

            &--hidden {
                display: none;
            }
        }

        &__link {
            display: block;
            text-transform: uppercase;
            padding: 9px 5px;
            width: 100%;
            font-family: $font_family-secondary;
            letter-spacing: 1.9px;

            &,
            &:visited,
            &:visited:hover,
            &:focus {
                color: $navigation_link-color;
            }

            &:hover {
                text-decoration: none;
                color: $navigation_link-color-hover;
                background-color: $color_main__brand;
            }

            &--main {
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }

            &--all-categories {
                font-weight: 700;
                margin: 0;
                border-bottom: $navigation_link-border;
                padding-bottom: 1.55em;
            }

            &--level {
                &_1,
                &_2 {
                    font-weight: 700;
                    margin: 0;
                    border-bottom: $navigation_link-border;
                    padding: 12px 15px;
                    line-height: 21px;
                    background: $navigation_flyout-background;
                    &:hover {
                        text-decoration: none;
                        color: $subnavigation_link-color;
                        background-color: $subnavigation_link-background-hover;
                    }

                    &,
                    &:visited,
                    &:visited:hover,
                    &:focus {
                        color: $subnavigation_link-color;
                    }
                }

                &_2 {
                    text-transform: none;
                    font-size: 1.25em;
                }
            }

            &--with-icon {
                position: relative;
                padding-left: 2.8rem;
            }

            &-icon-wrapper {
                position: absolute;
                width: 2rem;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }

            &-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                max-height: 1.8rem;

                #{$root}__link--category & {
                    margin-top: -0.5rem;
                }
            }

            &-products-qty {
                &:before {
                    content: '(';
                }

                &:after {
                    content: ')';
                }
            }
        }

        //Styling for categories level_1 with no subcategories
        #{$root}__item--no-children {
            padding: 0;
        }
        #{$root}__item--no-children > #{$root}__link--level_1 {
            text-transform: none;
            font-size: 1.12em;
            font-weight: 400;
            padding: 0.9em 1.5em;
            border: none;
            color: $navigation_link-color;
        }

        &__flyout {
            > ul {
                position: relative;
                li:hover {
                    > ul {
                        display: block;
                    }
                }
            }
        }

        &__flyout {
            position: absolute;
            display: none;
            left: 20px;
            backface-visibility: hidden;
            z-index: 1001; // Make sure flyouts cover gallery nav.
            box-shadow: $navigation_flyout-box-shadow;
            margin-top: 0;
            max-width: 215px;

            &--visible {
                display: flex;
            }

            svg {
                pointer-events: none;
            }

            li {
                @supports (-ms-ime-align: auto) {
                    list-style: none;
                    list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
                    list-style-type: none;
                }
            }

            ul {
                a {
                    text-transform: uppercase;
                    font-size: 14px;
                    color: $subnavigation_link-color;
                    letter-spacing: 1.5px;
                    font-weight: bold;
                    text-align: left;
                }

                ul {
                    position: absolute;
                    display: none;
                    right: -100%;
                    top: 0;
                    width: 100%;
                    backface-visibility: hidden;
                    z-index: 1001; // Make sure flyouts cover gallery nav.
                    box-shadow: $navigation_flyout-box-shadow;
                    margin-top: 0;
                    max-width: 215px;

                    li {
                        @supports (-ms-ime-align: auto) {
                            list-style: none;
                            list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
                            list-style-type: none;
                        }
                    }
                }
            }
        }

        &__list--level_2 {
            left: auto;
        }

        &__teaser {
            position: relative;
            max-height: 100%;
            padding: 1em 0;
            overflow: hidden;
            margin-bottom: 0 !important;

            &--hidden {
                display: none;
            }

            .#{$ns}image-teaser__figure {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &__products {
            padding: 0.5em 1em;
            min-width: 24rem;

            &-header {
                display: block;
                color: $navigation_link-color;
                text-transform: uppercase;
                width: 100%;
                font-weight: 700;
                margin: 0;
                border-bottom: $navigation_link-border;
                padding: 1.1em 1.05em 1.55em;
                font-size: 0.9em;
            }

            &-list {
                margin: 0;
                padding: 0;
                list-style: none;
            }
        }

        &__product {
            @include aspect-ratio(
                $width: $tile_aspect-ratio-width,
                $height: $tile_aspect-ratio-height,
                $childClass: #{$ns}product-tile,
                $autoposition: true
            );
        }

        &__overlay {
            display: none;
            position: absolute;
            z-index: 1000;
            background-color: #000;
            opacity: 0;
            left: 0;
            width: 100%;
            height: 0;
            transform: translate3d(0, 3rem, 0);
            transition: opacity 0.15s linear,
            transform 0.2s cubic-bezier(0, 0.97, 0.05, 0.99);

            @if $navigation_overlay-show {
                @include media('>=tablet') {
                    display: block;
                }
            }

            &--visible {
                opacity: 0.8;
                transform: translate3d(0, 0, 0);
            }
        }

        .page-print & {
            display: none;
        }

        @supports (display: grid) and (grid-auto-flow: row) {
            &__product {
                @include aspect-ratio-reset(
                    $childClass: #{$ns}product-tile,
                    $autoposition: true
                );
            }
        }
    }
}

.#{$ns}container {
    &--navigation {
        display: none;

        @include media('>=laptop') {
            display: block;
        }
    }
}
