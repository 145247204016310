@import 'config/variables';
@import 'utils/reset-list-style';
@import 'vendors/include-media';

$product_list-item-border-bottom: 1px solid $color_border-200;
$product_list-item-padding: 0;
$product_list-carousel-breakpoint: '>=tablet';

.#{$ns}products-list {
    $root: &;

    @include reset-list-style();

    &__item {
        margin: 0;
        border-top: 1px solid $color_border-light;

        .cs-addtocart__button {

            @include media('<laptop') {
                padding: 7px 5px;
                min-width: 165px;

                .cs-addtocart__button-label {
                    font-size: 14px;
                    padding: 0;
                }

                svg.cs-addtocart__button-icon {
                    position: relative;
                    left: -7px;
                    top: 11px;
                    width: 18px;
                    height: 15px;
                }
            }

            @include media('>tablet', '<laptop') {
                .cs-addtocart__button-label {
                    font-size: 12px;
                    padding: 0;
                    letter-spacing: 1px;
                }
            }

            @include media('<phoneLg') {
                .cs-addtocart__button-label {
                    font-size: 12px;
                    padding: 0;
                    letter-spacing: 1px;
                }
            }
        }

        .cs-product-tile__stock {
            text-transform: capitalize;
            @include media('<phoneLg') {
                padding: 0 9px;
                font-size: 1.4rem;
                line-height: 2.0rem;
                &:before {
                    width: 10px;
                    height: 10px;
                }
            }
        }

        .cs-product-tile__badges {
            position: absolute;
            right: 0;
            justify-content: flex-end;
            z-index: 1;
            .cs-product-tile__badge-item {
                padding-right: 0;
            }
            .cs-product-tile__badge {
                font-size: 9px;
                padding: 5px 6px 5px 13px;
                max-width: 80px;
            }
        }
    }

    .cs-product-tile__container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .cs-product-tile__thumbnail {
        width: 135px;
        padding: 10px;
        box-sizing: border-box;


        @include media('<=tablet') {
            min-width: 71px;
            padding: 14px 0px;
            width: 71px;
        }

        .cs-product-tile__figure {
            padding-bottom: 0;
        }
    }

    .cs-product-tile__content {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-top: 0;
        padding-left: 35px;
        position: relative;


        @include media('<phone') {
            flex-direction: column;
            padding-left: 20px;
            position: initial;
        }

        .cs-product-tile__details {
            @include media('<=tablet') {
                margin-bottom: 8px;
                flex-shrink: initial;
            }
        }

        .cs-product-tile__addons {
            display: flex;
            align-items: center;
            flex: 1;

            @include media('<laptop', '>phone') {
                align-items: flex-start;
            }

            @include media('<=phone') {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
            }
        }

        .cs-product-tile__addons-sides {
            position: absolute;
            left: 0;
            bottom: 10px;
            text-align: left;
            padding-left: 35px;

            @include media('<=tablet') {
                left: 0;
                bottom: 15px;
                width: auto;
            }

            @include media('<=phone') {
                left: 53px;
                bottom: 60px;
            }
        }
        .cs-product-tile__addons-left {
            display:none;
        }

        .cs-product-tile__addons-top {
            display: none;
        }
    }

    .cs-product-tile__main {
        padding-bottom: 40px;
        padding-right: 15px;
        width: 22vw;

        @include media('<=tablet') {
            padding-bottom: 35px;
            width: 100%;
            padding-right: 0;
        }

        @include media('<=phone') {
            padding-bottom: 85px;
        }
    }

    .cs-buybox__qty-wrapper {
        display: flex;

        @include media('>=tablet') {
           flex-direction: column;
            align-items: flex-start;
        }

        .cs-buybox__qty-label {
            font-size: 1.2rem;
            font-weight: bold;
            margin-right: 7px;

            @include media('>=laptop') {
               margin-top: -13px;
            }
        }

        @include media('<=tablet') {
            flex-direction: row;
            flex-wrap: wrap;

            .cs-buybox__qty-increment {
                flex: 1;
                min-width: 95px;
            }
        }
    }

    .cs-addtocart {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;

        @include media('<laptop', '>tablet') {
            flex-direction: column;
            margin: 13px 0 0;
            align-items: flex-end;
        }

        @include media('<=tablet', '>phone') {
            flex-direction: column;
            margin: 18px 0 0;
        }

        @include media('<=tablet') {
            justify-content: space-between;
            &.config-box {
                justify-content: flex-end;
            }
        }

        .cs-qty-increment,
        .cs-addtocart__wrapper {
            margin: 0;
        }

        .cs-qty-increment {
            width: 140px;

            @include media('<=tablet') {
                width: auto;
            }

            .cs-qty-increment__button {
                min-width: 30px;
                width: 34px;
                max-width: 34px;

                @include media('<=tablet') {
                    width: 28px;
                    height: 28px;
                    max-width: 28px;

                    svg {
                        width: 10px;
                    }
                }
            }

            .cs-qty-increment__input {
                @include media('<=tablet') {
                    width: 30px;
                    max-width: 30px;
                    min-width: 30px;
                    height: 28px;
                    font-size: 1.4rem;
                }
            }
        }

        .cs-addtocart__wrapper {
            flex: 1;
            max-width: 220px;
            margin-left: 50px;

            @include media('<laptop', '>tablet') {
                margin-top: 15px;
                margin-bottom: 10px;
            }

            @include media('<=tablet', '>phone') {
                margin-top: 35px;
            }

            @include media('<=tablet') {
                max-width: 165px;
                margin-left: 10px;
            }
        }

    }

    .cs-product-tile__name {
        margin-top: 15px;
        a {
            width: 22vw;

            @include media('<=tablet') {
                display: block;
                width: 100%;
            }
        }
    }

    &:not(#{$root}--carousel) {
        #{$root}__item {
            padding: $product_list-item-padding;

            &:last-child {
                border-bottom: 0;
            }
        }
    }

    &--carousel {
        @include media($product_list-carousel-breakpoint) {
            #{$root}__item {
                border-bottom: $product_list-item-border-bottom;
                padding: $product_list-item-padding;

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
}

.products-crosssell {
    padding-top: 60px;
    .cs-headline {
        @include media('<=laptop') {
            margin-bottom: 0;
        }
    }
    .cs-headline__title {
        text-align: center;
        font-size: 28px;
        letter-spacing: 1px;
        justify-content: center;

        @include media('<=laptop') {
            font-size: 18px;
        }
    }
}
