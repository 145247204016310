/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Remove browser's margins, paddings and list style rules
 */
.cs-accordion {
  margin: 0 0 3rem; }
  @media (min-width: 48em) {
    .cs-accordion {
      margin: 0 0 4rem; } }
  .cs-accordion--skip-spacing {
    margin: 0; }
  .cs-accordion__groups, .cs-accordion__items {
    margin: 0;
    padding: 0;
    list-style: none; }
  .cs-accordion__group {
    margin: 0 0 3rem; }
  .cs-accordion__group-headline {
    font-family: "Red Hat Display", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Oxygen, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.8rem;
    color: #212121;
    font-weight: 700;
    text-transform: uppercase;
    margin: 1rem 0 2rem;
    margin: 0 0 0.5rem; }
  .cs-accordion__item {
    margin: 0;
    border-bottom: 1px solid #dcdcdc;
    padding: 1rem 0; }
    .cs-accordion__item:first-child {
      border-top: 1px solid #dcdcdc; }
    .cs-accordion__item--active .cs-accordion__item-content {
      display: block; }
  .cs-accordion__item-headline {
    position: relative;
    cursor: pointer; }
    .cs-accordion__item-headline:before, .cs-accordion__item-headline:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 1rem;
      height: 0.2rem;
      left: auto;
      background-color: #FF4604;
      transition: transform 0.15s linear, right 0.15s linear, background-color 0.15s linear;
      backface-visibility: hidden; }
    .cs-accordion__item-headline:before {
      transform: rotate(45deg) translate3d(0, 0, 0);
      right: 2.1rem; }
    .cs-accordion__item-headline:after {
      transform: rotate(-45deg) translate3d(0, 0, 0);
      right: 1.5rem; }
    .cs-accordion__item-headline[aria-expanded="true"]:before {
      transform: rotate(-45deg); }
    .cs-accordion__item-headline[aria-expanded="true"]:after {
      transform: rotate(45deg); }
    .cs-accordion__item-headline:before, .cs-accordion__item-headline:after {
      right: auto; }
    .cs-accordion__item-headline:before {
      left: 1.5rem; }
    .cs-accordion__item-headline:after {
      left: 2.1rem; }
  .cs-accordion__item-headline-span {
    position: relative;
    display: inline-flex;
    align-items: center;
    font-weight: inherit;
    padding-left: 4.5rem; }
    @media (max-width: 47.99em) {
      .cs-accordion__item-headline-span {
        width: 100%; } }
  .cs-accordion__item-content {
    display: none;
    margin: 1rem 0 0; }
    @media (min-width: 48em) {
      .cs-accordion__item-content {
        padding-left: 4.5rem; } }
    .cs-accordion__item-content p:last-child {
      margin-bottom: 0; }
