/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/*
 * Because price-box is used in multiple components like tile, buybox etc,
 * we need to move variables to separate file to have possibility to include them
 * in each component without generate price-box component styles multiple times
*/
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/***
 * Using psuedo elements to maintain an elements aspect ratio, even as it scales
 * It's using only for IE11 and Edge (version which doesn't support native CSS grid) browsers
 * For browsers which support native CSS grid, we are using mixins contain `reset` in mixin name
 * to override default mixins (progressive enhancement)
 * https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
 * @param {number} $width: width of the element;
 * @param {number} $height: height of the element;
 * @param {string} $contentClass: class of the direct child element to be positioned absolutely;
 * @param {boolean} $autoposition: tells if item shall take 100% of parent size or if it can be wider/higher if needed;
 */
/**
 * String to number converter
 * https://www.sassmeister.com/gist/9fa19d254864f33d4a80
 * @author Hugo Giraudel
 * @param {String | Number} $value - Value to be parsed
 * @return {Number}
 */
/***
 * Remove browser's margins, paddings and list style rules
 */
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
.cs-product-tile {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 3px;
  background-color: #ffffff; }
  @media (min-width: 64em) {
    .cs-product-tile:hover {
      z-index: 5;
      transition: z-index 0.0001s; } }
  .cs-product-tile--clickable:hover {
    cursor: pointer; }
  .cs-product-tile--hidden .cs-product-tile__main,
  .cs-product-tile--hidden .cs-product-tile__addons {
    visibility: hidden !important; }
  .cs-product-tile--grid.cs-product-tile--static .cs-product-tile__addons {
    display: none; }
  .cs-product-tile__container {
    display: flex;
    height: 100%;
    width: 100%; }
    .cs-product-tile--grid .cs-product-tile__container {
      flex-direction: column;
      padding: 1.2rem; }
  .cs-product-tile--list .cs-product-tile__link {
    display: flex;
    width: 100%; }
  .cs-product-tile--grid .cs-product-tile__link {
    display: block;
    height: 100%; }
  .cs-product-tile__thumbnail {
    padding: 0;
    display: flex; }
  .cs-product-tile__thumbnail-link {
    display: block;
    position: relative;
    overflow: hidden; }
    .cs-product-tile--grid .cs-product-tile__thumbnail-link {
      width: 100%; }
    .cs-product-tile__thumbnail-link:hover, .cs-product-tile__thumbnail-link:visited {
      color: inherit;
      text-decoration: inherit; }
  .cs-product-tile__figure {
    margin: 0;
    padding: 0 0 15px;
    height: 100%;
    overflow: hidden; }
    .cs-product-tile--grid .cs-product-tile__figure {
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      top: 0;
      position: relative; }
      .cs-product-tile--grid .cs-product-tile__figure:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: 100%; }
      .cs-product-tile--grid .cs-product-tile__figure .cs-product-tile__thumbnail-link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
  .cs-product-tile__image {
    width: auto;
    height: auto;
    max-width: 100%;
    margin: auto; }
    .cs-product-tile--grid .cs-product-tile__image {
      position: absolute;
      top: 0;
      left: 0; }
    .cs-product-tile--list .cs-product-tile__image {
      max-height: 100%;
      position: relative !important;
      margin-top: 0; }
  .cs-product-tile__badges {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap; }
    .cs-product-tile--list .cs-product-tile__badges--list {
      display: none; }
    .cs-product-tile--grid .cs-product-tile__badges {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      flex-direction: column; }
    .cs-product-tile--list .cs-product-tile__badges {
      margin: 0; }
  .cs-product-tile__badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transform: none;
    background-color: #FF4604;
    color: #ffffff;
    border: 0;
    border-radius: 0;
    padding: 10px 15px 10px 25px;
    font-size: 1.3rem;
    font-weight: 600;
    min-height: 2em;
    text-transform: uppercase;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    max-width: 125px; }
    @media (max-width: 47.99em) {
      .cs-product-tile__badge {
        font-size: 10px;
        max-width: 88px;
        padding: 8px 13px; } }
    @media (min-width: 48.01em) {
      .cs-product-tile__badge {
        font-size: 13px; } }
    .cs-product-tile__badge--discount {
      background-color: #FF4604;
      color: #ffffff; }
      .cs-product-tile__badge--discount .cs-product-tile__badge-text {
        line-height: normal;
        transform: none;
        text-align: right; }
        .cs-product-tile__badge--discount .cs-product-tile__badge-text + .cs-product-tile__badge--discount .cs-product-tile__badge-text {
          margin-left: 0.5rem; }
    .cs-product-tile__badge--sale {
      background-color: #CC1C3A;
      color: #ffffff; }
      .cs-product-tile__badge--sale .cs-product-tile__badge-text {
        line-height: normal;
        transform: none;
        text-align: right; }
        .cs-product-tile__badge--sale .cs-product-tile__badge-text + .cs-product-tile__badge--sale .cs-product-tile__badge-text {
          margin-left: 0.5rem; }
    .cs-product-tile__badge--new {
      background-color: #A9C546;
      color: #ffffff; }
      .cs-product-tile__badge--new .cs-product-tile__badge-text {
        line-height: normal;
        transform: none;
        text-align: right; }
        .cs-product-tile__badge--new .cs-product-tile__badge-text + .cs-product-tile__badge--new .cs-product-tile__badge-text {
          margin-left: 0.5rem; }
    .cs-product-tile__badge--free-shipping {
      background-color: #A9C546;
      color: #ffffff; }
      .cs-product-tile__badge--free-shipping .cs-product-tile__badge-text {
        line-height: normal;
        transform: none;
        text-align: right; }
        .cs-product-tile__badge--free-shipping .cs-product-tile__badge-text + .cs-product-tile__badge--free-shipping .cs-product-tile__badge-text {
          margin-left: 0.5rem; }
    .cs-product-tile__badge--popular {
      background-color: #A9C546;
      color: #ffffff; }
      .cs-product-tile__badge--popular .cs-product-tile__badge-text {
        line-height: normal;
        transform: none;
        text-align: right; }
        .cs-product-tile__badge--popular .cs-product-tile__badge-text + .cs-product-tile__badge--popular .cs-product-tile__badge-text {
          margin-left: 0.5rem; }
    .cs-product-tile__badge--dailydeal {
      background-color: #CC1C3A;
      color: #ffffff; }
      .cs-product-tile__badge--dailydeal .cs-product-tile__badge-text {
        line-height: normal;
        transform: none;
        text-align: right; }
        .cs-product-tile__badge--dailydeal .cs-product-tile__badge-text + .cs-product-tile__badge--dailydeal .cs-product-tile__badge-text {
          margin-left: 0.5rem; }
  .cs-product-tile__badge-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
    .cs-product-tile--grid .cs-product-tile__badge-item {
      margin: 0 0 0.5rem;
      width: 100%; }
    .cs-product-tile--list .cs-product-tile__badge-item {
      margin: 0 0 0.3rem 0; }
    .cs-product-tile__badge-item:empty {
      display: none; }
  .cs-product-tile__badge-text {
    line-height: normal;
    transform: none;
    text-align: right; }
    .cs-product-tile__badge-text + .cs-product-tile__badge-text {
      margin-left: 0.5rem; }
  .cs-product-tile--list .cs-product-tile__gallery {
    display: none !important; }
  .cs-product-tile__content {
    padding: 0;
    display: flex;
    align-items: stretch;
    border-top: 1px solid #EFEFF0; }
    .cs-product-tile--grid .cs-product-tile__content {
      flex-direction: column;
      flex: 1; }
  .cs-product-tile--grid .cs-product-tile__addons {
    display: none; }
  .cs-product-tile__addons-top {
    width: 100%; }
  .cs-product-tile__addons-sides {
    display: flex;
    width: 100%; }
  .ie11 .cs-product-tile__addons-right {
    width: 30%; }
  .cs-product-tile--grid .cs-product-tile__addons-right {
    align-items: flex-end; }
  .cs-product-tile__addons-bottom {
    width: 100%; }
  .cs-product-tile__addons-left {
    padding-right: 1rem;
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    flex-direction: column; }
    .ie11 .cs-product-tile__addons-left {
      width: 70%; }
    .cs-product-tile--grid .cs-product-tile__addons-left {
      align-items: flex-start; }
  .cs-product-tile__review--grid {
    margin: 1rem 0 0; }
  .cs-product-tile__review:empty {
    margin: 0 !important; }
  .cs-product-tile--grid .cs-product-tile__review,
  .cs-product-tile--list .cs-product-tile__review {
    display: none; }
  .cs-product-tile__stock {
    display: flex;
    align-items: center;
    border-radius: 25px;
    padding: 0 10px;
    margin-right: 2px;
    color: #ffffff;
    font-size: 1.2rem;
    line-height: 2.4rem; }
    .cs-product-tile__stock--in {
      background-color: #A9C546; }
      .cs-product-tile__stock--in:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 10px;
        background: url("../images/icons/tick-white.svg");
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 2px; }
    .cs-product-tile__stock--out {
      background-color: #800000; }
      .cs-product-tile__stock--out:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 10px;
        background: url("../images/icons/out.svg");
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 2px; }
    .cs-product-tile--grid .cs-product-tile__stock {
      margin: 0; }
  .cs-product-tile--list .cs-product-tile__dailydeal--grid {
    display: none !important; }
  .cs-product-tile--grid .cs-product-tile__dailydeal {
    margin: 0; }
  .cs-product-tile--list .cs-product-tile__dailydeal {
    margin: 1rem 0 0 0; }
  .cs-product-tile__qty {
    margin-bottom: 1rem;
    width: 11rem;
    margin-left: auto; }
  .cs-product-tile__main {
    position: relative;
    display: flex;
    align-items: stretch; }
    .cs-product-tile--grid .cs-product-tile__main {
      flex-direction: column;
      height: 100%;
      justify-content: flex-start; }
      @media (min-width: 40.01em) {
        .cs-product-tile--grid .cs-product-tile__main {
          padding: 0 15px; } }
      @media (max-width: 40em) {
        .cs-product-tile--grid .cs-product-tile__main {
          justify-content: flex-start;
          padding: 0 5px; } }
  .cs-product-tile__details {
    flex-shrink: 0; }
  .cs-product-tile__swatches {
    display: flex; }
    .cs-product-tile--grid .cs-product-tile__swatches {
      margin: 1.5rem 0 1rem;
      min-height: 2.6rem; }
      .cs-product-tile--grid .cs-product-tile__swatches,
      .cs-product-tile--grid .cs-product-tile__swatches .swatch-attribute-options {
        justify-content: flex-start; }
    .cs-product-tile__swatches--list:empty {
      margin: 0 !important; }
    .ie11 .cs-product-tile__swatches {
      width: 100%; }
  .cs-product-tile__brand {
    height: 2rem;
    margin-top: 1.2rem;
    text-align: center; }
    .cs-product-tile__brand--empty {
      height: 0; }
  .cs-product-tile__brand-text {
    font-size: 1.2rem;
    color: #3E3E3E; }
  .cs-product-tile__name {
    line-height: 2.5rem;
    font-weight: 600;
    font-size: 1.4rem;
    margin-bottom: 5px;
    overflow: hidden;
    width: 100%; }
    @media (max-width: 40em) {
      .cs-product-tile__name {
        font-size: 13px;
        line-height: 18px; } }
    .cs-product-tile--grid .cs-product-tile__name {
      margin: 0;
      text-align: left; }
    .cs-product-tile--grid .cs-product-tile__details > .cs-product-tile__name {
      margin-top: 1.2rem; }
      @media (max-width: 40em) {
        .cs-product-tile--grid .cs-product-tile__details > .cs-product-tile__name {
          margin-top: 5px;
          margin-bottom: 10px; } }
    .cs-product-tile--grid .cs-product-tile__details > .cs-product-tile__brand + .cs-product-tile__name {
      margin-top: 0; }
    .cs-product-tile--grid .cs-product-tile__details > .cs-product-tile__brand--empty + .cs-product-tile__name {
      height: 7.2rem; }
  .cs-product-tile__name-link {
    color: #212121;
    transition: color 0.15s linear;
    /* disabled because autoprefixer can't build this */
    /* stylelint-disable value-no-vendor-prefix */
    /* stylelint-disable property-no-vendor-prefix */
    /* autoprefixer: ignore next */
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    /* autoprefixer: ignore next */
    -webkit-line-clamp: 2;
    /* autoprefixer: ignore next */
    line-clamp: 2;
    max-width: 100%;
    /* stylelint-enable value-no-vendor-prefix */
    /* stylelint-enable property-no-vendor-prefix */ }
    .cs-product-tile__name-link:visited {
      color: #212121; }
    .cs-product-tile__name-link:hover {
      color: #FF7442;
      text-decoration: none; }
  .cs-product-tile__description {
    display: none;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.4;
    color: #696969;
    margin: 0 0 1rem; }
    .cs-product-tile--grid .cs-product-tile__description {
      display: none; }
    .cs-product-tile__description:empty {
      margin: 0 !important; }
  .cs-product-tile__additional--list {
    margin: auto 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%; }
    @media (min-width: 48em) {
      .cs-product-tile__additional--list {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between; } }
    .cs-products-list--with-sidebar .cs-product-tile__additional--list {
      flex-direction: column;
      align-items: flex-start; }
      @media (min-width: 64em) {
        .cs-products-list--with-sidebar .cs-product-tile__additional--list {
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-between; } }
  .ie11 .cs-product-tile__addto {
    width: 100%; }
  .cs-product-tile--grid .cs-product-tile__addto--grid {
    display: none; }
  .cs-product-tile--list .cs-product-tile__addto--grid {
    display: none; }
  .cs-product-tile--list .cs-product-tile__addto--list {
    display: none; }
  .cs-product-tile--grid .cs-product-tile__addto--list {
    display: none; }
  .cs-product-tile--grid .cs-product-tile__addto {
    margin: 1rem 0 0; }
  .cs-product-tile--list .cs-product-tile__addto {
    margin: 1rem 0 0; }
  .cs-product-tile__addto .cs-links-block-addto__link {
    font-size: 1.3rem; }
  .cs-dashboard__bottom-wishlist .cs-product-tile__addto,
  .cs-dashboard__wishlist-products-grid .cs-product-tile__addto {
    display: none; }
  .cs-product-tile__addto:empty {
    margin: 0 !important; }
  .ie11 .cs-product-tile__addto .cs-links-block-addto__item--wishlist {
    width: 100%; }
  .ie11 .cs-product-tile__addto .cs-links-block-addto--grid {
    width: 100%; }
  .cs-product-tile--grid .cs-product-tile__addtocart {
    margin: 1rem 0 0; }
  .cs-product-tile--list .cs-product-tile__addtocart {
    margin: 0.5rem 0 0; }
  .cs-product-tile__addtocart-button {
    width: 100%; }
  .cs-product-tile__wishlist-remove-icon {
    position: absolute;
    top: 0;
    right: 0;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem; }
    @media (pointer: fine) {
      .cs-product-tile__wishlist-remove-icon {
        display: none; } }
  .cs-product-tile__wishlist-remove-icon-image {
    width: 1.2rem;
    height: 1.2rem;
    fill: #6E6E6E; }
  .cs-product-tile__wishlist-comment {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    /* 1 */ }
    .cs-product-tile--list .cs-product-tile__wishlist-comment {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      white-space: nowrap;
      /* 1 */ }
  .cs-product-tile__wishlist-actions {
    margin: 1rem 0 0;
    display: flex;
    justify-content: space-between; }
  .cs-product-tile__price {
    line-height: 1.2; }
    @media (max-width: 40em) {
      .cs-product-tile__price {
        font-size: 13px; } }
    .cs-product-tile--grid .cs-product-tile__price--grid {
      display: block; }
    .cs-product-tile--list .cs-product-tile__price--list {
      display: none; }
    .cs-product-tile__price .price-box {
      display: flex;
      flex-direction: column; }
      .cs-product-tile__price .price-box .price-label {
        margin: 0 3px 0 0; }
      .cs-product-tile__price .price-box .price {
        font-size: 1.4rem;
        font-weight: 400;
        color: #000; }
        @media (max-width: 40em) {
          .cs-product-tile__price .price-box .price {
            font-size: 13px;
            letter-spacing: 0.5px; } }
      .cs-product-tile__price .price-box .map-old-price .price {
        font-size: 1.4rem; }
      .cs-product-tile__price .price-box .old-price .price {
        font-size: 1.4rem;
        font-weight: 400;
        color: #000;
        text-decoration: none; }
        @media (max-width: 40em) {
          .cs-product-tile__price .price-box .old-price .price {
            font-size: 13px;
            letter-spacing: 0.5px; } }
      .cs-product-tile__price .price-box .with-special-price {
        margin-right: 4px; }
      .cs-product-tile__price .price-box .special-price,
      .cs-product-tile__price .price-box .with-special-price {
        margin-right: 5px; }
        .cs-product-tile__price .price-box .special-price .price,
        .cs-product-tile__price .price-box .special-price .price-label,
        .cs-product-tile__price .price-box .with-special-price .price,
        .cs-product-tile__price .price-box .with-special-price .price-label {
          font-size: 1.4rem;
          font-weight: 400;
          color: #CC1C3A; }
          @media (max-width: 40em) {
            .cs-product-tile__price .price-box .special-price .price,
            .cs-product-tile__price .price-box .special-price .price-label,
            .cs-product-tile__price .price-box .with-special-price .price,
            .cs-product-tile__price .price-box .with-special-price .price-label {
              font-size: 13px;
              letter-spacing: 0.5px; } }
      .cs-product-tile__price .price-box .minimal-price {
        margin: 0;
        display: flex; }
      .cs-product-tile--grid .cs-product-tile__price .price-box {
        margin: 0;
        align-items: flex-start;
        justify-content: flex-start; }
        .cs-product-tile--grid .cs-product-tile__price .price-box .minimal-price {
          align-items: flex-end; }
        .cs-product-tile--grid .cs-product-tile__price .price-box .old-price .price {
          margin-right: 1rem; }
    .cs-product-tile__price--dailydeal-countdown .price-box:not(.price-final_price_without_daily_deal) {
      display: none; }
    .cs-product-tile__price--dailydeal-countdown .price-box.price-box--visible {
      display: flex; }

@media (max-width: 40em) {
  .cs-container--products-carousel .cs-product-tile__container {
    padding: 1.5rem 3rem; } }

@media (max-width: 40em) {
  .cs-container--products-carousel .cs-products-carousel__nav--prev {
    left: -14px; } }

@media (max-width: 40em) {
  .cs-container--products-carousel .cs-products-carousel__nav--next {
    right: -14px; } }
