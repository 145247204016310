/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-page-teaser--container {
  position: relative; }
  .cs-page-teaser--container picture {
    top: 0;
    left: 0;
    opacity: 0; }
  .cs-page-teaser--container picture,
  .cs-page-teaser--container img {
    display: block; }
  .cs-page-teaser--container .cms-page__teaser-wrapper {
    height: 351px; }
    @media (max-width: 47.99em) {
      .cs-page-teaser--container .cms-page__teaser-wrapper {
        height: 200px !important; } }
  .cs-page-teaser--container .cms-page__teaser-image {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 351px; }
    @media (max-width: 47.99em) {
      .cs-page-teaser--container .cms-page__teaser-image {
        min-height: 200px; } }
  .cs-page-teaser--container .cms-page__teaser > picture,
  .cs-page-teaser--container .cms-page__teaser > img {
    display: none;
    visibility: hidden; }
  .cs-page-teaser--container .page-title-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center; }
  @media (min-width: 48em) {
    .cs-page-teaser--container .cms-page__teaser:after {
      content: '';
      display: block;
      background-image: url("../images/icons/arrow-down@1x.svg");
      background-repeat: no-repeat;
      background-size: contain;
      height: 40px;
      width: 40px;
      position: absolute;
      bottom: 20px;
      left: 50%;
      margin-left: -20px; } }
  @media (max-width: 47.99em) {
    .cs-page-teaser--container .cms-page__teaser-image {
      min-height: 200px !important; } }

.page-title {
  font-family: "Red Hat Display", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Oxygen, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 4rem;
  color: #212121;
  font-weight: 700;
  text-transform: uppercase;
  margin: 1rem 0 2rem;
  padding-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff; }
  @media (max-width: 47.99em) {
    .page-title {
      font-size: 3rem;
      text-align: center; } }
  .page-title__headline {
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    display: inline; }
  .page-title__span {
    color: #ffffff;
    font-weight: 600;
    padding: 0; }

.cms-page-view .page-title {
  padding: 0;
  margin: 0; }
