@import 'config/variables';
@import 'components/typography/mixin';
@import 'components/button/mixin';
@import 'utils/visually-hidden';
@import 'vendors/include-media';

$modal_title-font-size: $font-size_headline-3 !default;
$modal_secondary-button-type: 'secondary' !default;
$modal_secondary-button-icon-pos: 'no-icon' !default;
$modal_inside-borders: 1px solid $color_border-500 !default;
$modal_background: $color_background-200 !default;
$modal_slide-mobile-background: $color_background-200 !default;
$modal_box-shadow: $box-shadow_base !default;
$modal_border-radius: 0 !default;
$modal-border: none !default;
$modal_text-font-size: $font-size_paragraph !default;
$modal_primary-button-type: $button_default-type !default;
$modal_primary-button-icon-pos: 'no-icon' !default;
$modal_secondary-button-type: 'secondary' !default;
$modal_secondary-button-icon-pos: 'no-icon' !default;

/* stylelint-disable no-descending-specificity */

.modal-slide,
.modal-popup,
.modal-custom {
    &.confirm {
        .modal-inner-wrap {
            max-width: 50rem;
        }
    }

    .modal-inner-wrap {
        background: $modal_background;
        box-shadow: $modal_box-shadow;
        border-radius: $modal_border-radius;
        border: $modal-border;
    }

    .modal-content {
        padding-top: 0;
        padding-bottom: 2rem;
        font-size: $modal_text-font-size;
    }

    .modal-header {
        padding: 0;
        margin: 0;
        min-height: 65px;
    }

    .action-close {
        background: none;
        border: none;
        position: absolute;
        right: 0;
        top: 0;
        margin: 1.5rem;
        background-image: url('../images/icons/close.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 15px;
        width: 3rem;
        height: 3rem;

        // IE11 cuts background when in rem units
        .ie11 & {
            background-size: 10px 10px;
        }

        span {
            @include visually-hidden();
        }
    }

    .modal-footer {
        padding: 2rem;
        border-top: $modal_inside-borders;
        display: flex;
        justify-content: space-between;

        @include media('<phoneLg') {
            flex-wrap: wrap;
        }

        .action-primary,
        .primary {
            @include button(
                $type: $modal_primary-button-type,
                $icon_pos: $modal_primary-button-icon-pos
            );
            min-width: 12rem;

            @include media('<phoneLg') {
                width: 100%;
            }

            span {
                @include button-span(
                    $type: $modal_primary-button-type,
                    $icon_pos: $modal_primary-button-icon-pos
                );
            }
        }

        .action-secondary,
        .secondary {
            @include button(
                $type: $modal_secondary-button-type,
                $icon_pos: $modal_secondary-button-icon-pos
            );
            min-width: 12rem;

            @include media('<phoneLg') {
                width: 100%;
                margin-bottom: 1.5rem !important;
            }

            span {
                @include button-span(
                    $type: $modal_secondary-button-type,
                    $icon_pos: $modal_secondary-button-icon-pos
                );
            }

            // Style for checkout modals have very high specificity in magento
            body.checkout-index-index & {
                @include button(
                    $type: $modal_secondary-button-type,
                    $icon_pos: $modal_secondary-button-icon-pos
                );
                min-width: 12rem;

                span {
                    @include button-span(
                        $type: $modal_secondary-button-type,
                        $icon_pos: $modal_secondary-button-icon-pos
                    );
                }
            }
        }

        button:only-child {
            @include button(
                $type: $modal_primary-button-type,
                $icon_pos: $modal_primary-button-icon-pos
            );
            min-width: 12rem;

            span {
                @include button-span(
                    $type: $modal_primary-button-type,
                    $icon_pos: $modal_primary-button-icon-pos
                );
            }
        }
    }

    .modal-title {
        @include headline($level: $modal_title-font-size);
        padding: 2rem;
        border-bottom: $modal_inside-borders;
        margin: 0;
    }
}

.modal-popup.modal-slide {
    .modal-inner-wrap[class] {
        @include media('<tablet') {
            background-color: $modal_slide-mobile-background;
        }
    }
}

.modal-slide {
    .modal-footer {
        @include media('<phoneLg') {
            flex-wrap: wrap;
        }

        .action-primary,
        .primary {
            @include media('<phoneLg') {
                width: 100%;
            }
        }

        .action-secondary,
        .secondary {
            @include media('<phoneLg') {
                width: 100%;
                margin-bottom: 1.5rem !important;
            }
        }
    }
}

.ie11 .modal-popup:not(.cs-video-player__modal) .modal-content {
    max-height: calc(100vh - 33rem);
}

.page-layout-checkout {
    .modal-popup {
        pointer-events: auto;
    }

    .modal-slide {
        @include media('>=tablet') {
            width: 80%;
            max-width: 65rem;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

/* stylelint-enable */
// popup ajax add to cart
.fancybox-overlay {
    background-image: none;
    background-color: rgba(255,255,255,0.9);
}
.fancybox-desktop {
    &.fancybox-type-iframe.fancybox-opened {
        max-width: 1170px;
        width: 85vw !important;
        @include media('<=phoneLg') {
            width: 95vw !important;
            left: 2.5vw !important;
        }
    }

    &.fancybox-type-inline {
        max-width: 480px;
        width: 95vw !important;

        @include media('<=phoneLg') {
            left: 2.5vw !important;
        }
        .fancybox-skin {
            background: $color_white;
        }

        .header,
        .cart-info {
            display: none;
        }
    }

    .fancybox-inner {
        width: 100% !important;
        max-height: 735px !important;
        padding: 45px 0;
        background: $color_white;

        @include media('<=phoneLg') {
            padding: 40px 0 0px;
        }
    }
}

.fancybox-skin {
    .fancybox-close{
        background: none;
        left: 31px;
        right: auto;
        top: 20px;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $color_black;
        width: auto;
        height: 23px;

        @include media('<=tablet') {
            left: 25px;
            top: 12px;
        }

        &:before,
        &:after {
            text-transform: uppercase;
            display: inline-block;
            font-weight: bold;
            color: $color_black;
            font-size: 14px;
            line-height: 18px;
            font-family: $font_family-primary;
        }
        &:before {
            content: 'X';
            margin-right: 5px;
        }
        &:after {
            content: 'Close';
        }
    }
}

#bss_ajaxcart_popup {
    .product-image {
        max-width: 310px;
        margin: 0 auto;
    }

    .message {
        margin: 0;
        padding-left: 1rem;
    }

    .btn-viewcart {
        button.viewcart {
            @include button(
                $type: 'secondary',
                $icon_pos: null,
                $text_transform: uppercase,
                $border-radius: 25px
            );
            background: $color_black !important;

            &:hover,
            &:focus,
            &:active {
                background: $color_body-text !important;
            }
        }
    }
    .btn-continue {
        button.continue {
            @include button(
                $type: 'primary',
                $icon_pos: null,
                $text_transform: uppercase,
                $border-radius: 25px
            );
            background: $color_main__brand !important;

            &:hover,
            &:focus,
            &:active {
                background: $color_button__hover !important;
            }

        }

        .countdown {
            display: none;
        }
    }
}

.ajaxcart-index-view {
    #ajax-goto {
        display: none !important;
    }

    .bss-notice {
        padding: 0 30px;
    }

    .cs-container--page-pdp-top {
        padding: 0 25px;

        @include media('<=tablet') {
            padding: 0 15px;
        }
    }

    .cs-page-product__gallery {
        @include media('>=tablet') {
            width: calc(100% - 49.5%);
        }
    }

    .cs-buybox__section,
    .cs-container--page-pdp-bundle-config {
        padding: 0;
    }
}


.popup-authentication {
    .modal-inner-wrap {
        max-width: 480px;
    }

    .action-close {
        background-image: none;
        width: auto;
        height: auto;
        margin: 22px 30px 0 0;
        padding: 1px 2px;
        border-bottom: 1px solid #000;

        &:before {
            content: 'X CLOSE';
            display: block;
            color: $color_dark-gray;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
        }
    }
    .block-title {
        font-size: 22px;
        margin-bottom: 20px;
    }
    .actions-toolbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 30px;

        .action-login {
            @include button(
                $type: $modal_primary-button-type,
                $icon_pos: false
            );
            padding: 10px 25px;
            margin-right: 20px;
            min-width: 145px;
        }
    }
}

.checkout-regions-popup,
.agreements-modal,
.google_popup {
    .modal-inner-wrap {
        width: 100%;
        border-radius: 10px;

        .modal-title {
            padding: 25px 30px;
            letter-spacing: 1px;
            border: 0;
            text-transform: capitalize;
            font-size: 40px;

            @include media('<=tablet') {
                font-size: 32px;
                padding: 20px 15px;
            }
        }

        .action-close {
            background-image: none;
            width: auto;
            height: auto;
            margin: 37px 41px 0 0;
            padding: 1px 2px;
            border-bottom: 1px solid #000;

            @include media('<=tablet') {
                margin: 30px 15px 0 0;
            }

            &:before {
                content: 'X CLOSE';
                display: block;
                color: $color_dark-gray;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 12px;
            }
        }
    }
}

.agreements-modal,
.google_popup {
    .modal-inner-wrap {
        @include media('<=tablet') {
            border-radius: 0;
        }

        .action-close {
            margin: 22px 41px 0 0;

            @include media('<=tablet') {
                margin: 22px 15px 0 0;
            }
        }
    }
}

.google_popup {
    .modal-inner-wrap {
        width: 96%;
        max-width: 640px;
    }
}

.modal_hidden {
    display: none !important;
}

.modal-popup {
    .modal_hidden {
        display: block !important;
    }
}

