/*
 * Fair warning: Don't mix units while using 'utils/poly-fluid-sizing' feature.
 * If you use rems, stick to rems everywhere in the area of whole map, or else the mixin will throw an error
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Using psuedo elements to maintain an elements aspect ratio, even as it scales
 * It's using only for IE11 and Edge (version which doesn't support native CSS grid) browsers
 * For browsers which support native CSS grid, we are using mixins contain `reset` in mixin name
 * to override default mixins (progressive enhancement)
 * https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
 * @param {number} $width: width of the element;
 * @param {number} $height: height of the element;
 * @param {string} $contentClass: class of the direct child element to be positioned absolutely;
 * @param {boolean} $autoposition: tells if item shall take 100% of parent size or if it can be wider/higher if needed;
 */
/**
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L179
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-grid-layout {
  position: relative;
  width: 100%; }
  .cs-grid-layout__grid {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none; }
    .cs-grid-layout__grid:after {
      content: '';
      display: table;
      clear: both; }
  .cs-grid-layout__teaser {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
    .cs-grid-layout__teaser-content-wrapper {
      display: flex;
      width: 100%;
      height: 100%; }
    .cs-grid-layout__teaser-figure {
      position: relative;
      width: 100%;
      height: 100%; }
    .cs-grid-layout__teaser-image {
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0); }
    .cs-grid-layout__teaser-content {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
      line-height: 1.15;
      /* Variants will be refactored after CC IT refactor due to new position options */ }
      .cs-grid-layout__teaser-content--variant-1 {
        justify-content: center;
        align-items: flex-start; }
      .cs-grid-layout__teaser-content--variant-2 {
        justify-content: flex-end;
        align-items: flex-start; }
      .cs-grid-layout__teaser-content--variant-3 {
        justify-content: center;
        align-items: center;
        text-align: center; }
      .cs-grid-layout__teaser-content--variant-4 {
        justify-content: flex-end;
        align-items: center;
        text-align: center; }
    .cs-grid-layout__teaser-content-inner {
      max-width: 100%; }
    .cs-grid-layout__teaser-headline {
      margin: 0 0 0.2em;
      color: #212121;
      font-weight: 600;
      text-transform: none;
      font-size: 1.8rem; }
      @media (min-width: 32rem) {
        .cs-grid-layout__teaser-headline {
          font-size: calc(1.08696vw + 1.45217rem); } }
      @media (min-width: 124rem) {
        .cs-grid-layout__teaser-headline {
          font-size: 2.8rem; } }
      .cs-grid-layout__brick--x2 .cs-grid-layout__teaser-headline {
        margin: 0 0 0.2em;
        font-size: 1.8rem; }
        @media (min-width: 32rem) {
          .cs-grid-layout__brick--x2 .cs-grid-layout__teaser-headline {
            font-size: calc(2.3913vw + 1.03478rem); } }
        @media (min-width: 124rem) {
          .cs-grid-layout__brick--x2 .cs-grid-layout__teaser-headline {
            font-size: 4rem; } }
      .cs-grid-layout__teaser--scheme-light .cs-grid-layout__teaser-headline {
        color: #ffffff; }
    .cs-grid-layout__teaser-subheadline {
      display: block;
      margin: 0 0 0.6em;
      color: #212121;
      font-weight: 400;
      text-transform: none;
      font-size: 1.6rem; }
      @media (min-width: 32rem) {
        .cs-grid-layout__teaser-subheadline {
          font-size: calc(0.21739vw + 1.53043rem); } }
      @media (min-width: 124rem) {
        .cs-grid-layout__teaser-subheadline {
          font-size: 1.8rem; } }
      .cs-grid-layout__brick--x2 .cs-grid-layout__teaser-subheadline {
        margin: 0 0 0.6em;
        font-size: 1.6rem; }
        @media (min-width: 32rem) {
          .cs-grid-layout__brick--x2 .cs-grid-layout__teaser-subheadline {
            font-size: calc(0.97826vw + 1.28696rem); } }
        @media (min-width: 124rem) {
          .cs-grid-layout__brick--x2 .cs-grid-layout__teaser-subheadline {
            font-size: 2.5rem; } }
      .cs-grid-layout__teaser--scheme-light .cs-grid-layout__teaser-subheadline {
        color: #ffffff; }
    .cs-grid-layout__teaser-text {
      margin: 0 0 1em;
      color: #212121;
      font-weight: 400;
      text-transform: none;
      font-size: 1.3rem; }
      @media (min-width: 32rem) {
        .cs-grid-layout__teaser-text {
          font-size: calc(0.1087vw + 1.26522rem); } }
      @media (min-width: 124rem) {
        .cs-grid-layout__teaser-text {
          font-size: 1.4rem; } }
      .cs-grid-layout__brick--x2 .cs-grid-layout__teaser-text {
        margin: 0 0 1em;
        font-size: 1.3rem; }
        @media (min-width: 32rem) {
          .cs-grid-layout__brick--x2 .cs-grid-layout__teaser-text {
            font-size: calc(0.32609vw + 1.19565rem); } }
        @media (min-width: 124rem) {
          .cs-grid-layout__brick--x2 .cs-grid-layout__teaser-text {
            font-size: 1.6rem; } }
      .cs-grid-layout__teaser--scheme-light .cs-grid-layout__teaser-text {
        color: #ffffff; }
    .cs-grid-layout__teaser-cta-button {
      display: inline-block;
      font-size: 1.4rem;
      font-weight: 700;
      text-align: center;
      position: relative;
      line-height: 1.275em;
      margin: 0;
      text-transform: uppercase;
      border-radius: 25px;
      font-family: "Red Hat Display";
      padding: 8px 25px;
      font-size: 1.2rem;
      box-sizing: border-box;
      transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
      vertical-align: middle;
      z-index: 5;
      will-change: transform, background-color, border-color, fill, color; }
      .cs-grid-layout__teaser-cta-button.medium {
        padding: 11px 25px;
        font-size: 1.4rem; }
      .cs-grid-layout__teaser-cta-button.large {
        padding: 13px 25px;
        font-size: 1.6rem; }
      .cs-grid-layout__teaser-cta-button::-moz-focus-inner {
        border: 0;
        padding: 0;
        margin: 0; }
      .cs-grid-layout__teaser-cta-button, .cs-grid-layout__teaser-cta-button:visited {
        cursor: pointer;
        border-radius: 25px;
        outline-offset: 1px;
        text-decoration: none;
        box-shadow: none;
        border: 1px solid transparent;
        color: #ffffff;
        background-color: #FF4604; }
        .cs-grid-layout__teaser-cta-button *, .cs-grid-layout__teaser-cta-button:visited * {
          color: #ffffff;
          fill: #ffffff; }
      .cs-grid-layout__teaser-cta-button:hover:not([disabled]), .cs-grid-layout__teaser-cta-button:focus:not([disabled]), .cs-grid-layout__teaser-cta-button:active:not([disabled]) {
        box-shadow: none;
        outline: none;
        text-decoration: none;
        border: 1px solid transparent;
        color: #ffffff;
        background-color: #FF7442; }
        .cs-grid-layout__teaser-cta-button:hover:not([disabled]) *, .cs-grid-layout__teaser-cta-button:focus:not([disabled]) *, .cs-grid-layout__teaser-cta-button:active:not([disabled]) * {
          color: #ffffff;
          fill: #ffffff; }
      .cs-grid-layout__teaser-cta-button[disabled] {
        opacity: 0.65;
        cursor: auto; }
      .cs-grid-layout__teaser-cta-button-span {
        margin-top: 0;
        z-index: 1;
        font-weight: 700;
        vertical-align: baseline;
        display: inline-block;
        line-height: 1.275em;
        letter-spacing: normal;
        padding-right: 4em; }
      .cs-grid-layout__teaser-cta-button-icon {
        max-width: 1em;
        max-height: 1.4rem;
        z-index: 2;
        height: auto;
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        margin-right: 0;
        right: 1em; }
  .cs-grid-layout__brick {
    width: 100%;
    margin: 0;
    padding: 0;
    float: left;
    position: relative; }
    .cs-grid-layout__brick:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 170%; }
    .cs-grid-layout__brick .cs-product-tile {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    @media (min-width: 30em) {
      .cs-grid-layout__brick {
        width: calc(100% / 2); }
        .cs-grid-layout__brick--x2 {
          width: calc(100% / 2 * 2); } }
    @media (min-width: 40em) {
      .cs-grid-layout__brick {
        width: calc(100% / 2); }
        .cs-grid-layout__brick--x2 {
          width: calc(100% / 2 * 2); } }
    @media (min-width: 48em) {
      .cs-grid-layout__brick {
        width: calc(100% / 3); }
        .cs-grid-layout__brick--x2 {
          width: calc(100% / 3 * 2); } }
    @media (min-width: 64em) {
      .cs-grid-layout__brick {
        width: calc(100% / 5); }
        .cs-grid-layout__brick--x2 {
          width: calc(100% / 5 * 2); } }
    @media (min-width: 80em) {
      .cs-grid-layout__brick {
        width: calc(100% / 5); }
        .cs-grid-layout__brick--x2 {
          width: calc(100% / 5 * 2); } }
    @media (min-width: 90em) {
      .cs-grid-layout__brick {
        width: calc(100% / 5); }
        .cs-grid-layout__brick--x2 {
          width: calc(100% / 5 * 2); } }
    @media (min-width: 120em) {
      .cs-grid-layout__brick {
        width: calc(100% / 5); }
        .cs-grid-layout__brick--x2 {
          width: calc(100% / 5 * 2); } }
    .cs-grid-layout__brick--x2:not(.cs-grid-layout__brick--y2):before {
      padding-top: 85%; }
    @media (max-width: 39.99em) {
      .cs-grid-layout__brick--x2:not(.cs-grid-layout__brick--y2).cs-grid-layout__brick--teaser:before {
        padding-top: 0; } }
    .cs-grid-layout__brick--y2.cs-grid-layout__brick--left {
      float: left; }
    .cs-grid-layout__brick--y2:not(.cs-grid-layout__brick--x2):before {
      padding-top: 340%; }
    @media (max-width: 39.99em) {
      .cs-grid-layout__brick--y2.cs-grid-layout__brick--teaser:before {
        padding-top: 0; } }
    .cs-grid-layout__brick--right {
      float: right; }
    .cs-grid-layout__brick--hidden {
      display: none !important; }
    .cs-grid-layout__brick--teaser {
      display: none; }
      @media (max-width: 39.99em) {
        .cs-grid-layout__brick--teaser:before {
          padding-top: 0; } }
      .cs-grid-layout__brick--teaser-ready {
        display: block; }
  @media (min-width: 30em) {
    .cs-grid-layout--with-sidebar .cs-grid-layout__brick {
      width: calc(100% / 2); }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--x2 {
        width: calc(100% / 2 * 2); } }
  @media (min-width: 40em) {
    .cs-grid-layout--with-sidebar .cs-grid-layout__brick {
      width: calc(100% / 2); }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--x2 {
        width: calc(100% / 2 * 2); } }
  @media (min-width: 48em) {
    .cs-grid-layout--with-sidebar .cs-grid-layout__brick {
      width: calc(100% / 2); }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--x2 {
        width: calc(100% / 2 * 2); } }
  @media (min-width: 64em) {
    .cs-grid-layout--with-sidebar .cs-grid-layout__brick {
      width: calc(100% / 3); }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--x2 {
        width: calc(100% / 3 * 2); } }
  @media (min-width: 80em) {
    .cs-grid-layout--with-sidebar .cs-grid-layout__brick {
      width: calc(100% / 3); }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--x2 {
        width: calc(100% / 3 * 2); } }
  @media (min-width: 90em) {
    .cs-grid-layout--with-sidebar .cs-grid-layout__brick {
      width: calc(100% / 3); }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--x2 {
        width: calc(100% / 3 * 2); } }
  @media (min-width: 120em) {
    .cs-grid-layout--with-sidebar .cs-grid-layout__brick {
      width: calc(100% / 3); }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--x2 {
        width: calc(100% / 3 * 2); } }
  @supports (display: grid) and (grid-auto-flow: row) {
    .cs-grid-layout__grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto; }
      @media (max-width: 39.99em) {
        .cs-grid-layout__grid {
          grid-template-columns: repeat(2, 1fr); } }
      @media (min-width: 30em) {
        .cs-grid-layout__grid {
          grid-template-columns: repeat(2, 1fr); } }
      @media (min-width: 40em) {
        .cs-grid-layout__grid {
          grid-template-columns: repeat(2, 1fr); } }
      @media (min-width: 48em) {
        .cs-grid-layout__grid {
          grid-template-columns: repeat(3, 1fr); } }
      @media (min-width: 64em) {
        .cs-grid-layout__grid {
          grid-template-columns: repeat(5, 1fr); } }
      @media (min-width: 80em) {
        .cs-grid-layout__grid {
          grid-template-columns: repeat(5, 1fr); } }
      @media (min-width: 90em) {
        .cs-grid-layout__grid {
          grid-template-columns: repeat(5, 1fr); } }
      @media (min-width: 120em) {
        .cs-grid-layout__grid {
          grid-template-columns: repeat(5, 1fr); } }
      @media (min-width: 30em) {
        .cs-grid-layout--with-sidebar .cs-grid-layout__grid {
          grid-template-columns: repeat(2, 1fr); } }
      @media (min-width: 40em) {
        .cs-grid-layout--with-sidebar .cs-grid-layout__grid {
          grid-template-columns: repeat(2, 1fr); } }
      @media (min-width: 48em) {
        .cs-grid-layout--with-sidebar .cs-grid-layout__grid {
          grid-template-columns: repeat(2, 1fr); } }
      @media (min-width: 64em) {
        .cs-grid-layout--with-sidebar .cs-grid-layout__grid {
          grid-template-columns: repeat(3, 1fr); } }
      @media (min-width: 80em) {
        .cs-grid-layout--with-sidebar .cs-grid-layout__grid {
          grid-template-columns: repeat(3, 1fr); } }
      @media (min-width: 90em) {
        .cs-grid-layout--with-sidebar .cs-grid-layout__grid {
          grid-template-columns: repeat(3, 1fr); } }
      @media (min-width: 120em) {
        .cs-grid-layout--with-sidebar .cs-grid-layout__grid {
          grid-template-columns: repeat(3, 1fr); } }
      .cs-grid-layout__grid:after {
        display: none; }
    .cs-grid-layout__brick {
      position: relative; }
      .cs-grid-layout__brick:before {
        content: none; }
      .cs-grid-layout__brick .cs-product-tile {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto; }
      @media (min-width: 30em) {
        .cs-grid-layout__brick--x2 {
          grid-column-end: span 2; }
        .cs-grid-layout__brick--y2 {
          grid-row-end: span 2; } }
      @media (min-width: 40em) {
        .cs-grid-layout__brick--x2 {
          grid-column-end: span 2; }
        .cs-grid-layout__brick--y2 {
          grid-row-end: span 2; } }
      @media (min-width: 48em) {
        .cs-grid-layout__brick--x2 {
          grid-column-end: span 2; }
        .cs-grid-layout__brick--y2 {
          grid-row-end: span 2; } }
      @media (min-width: 64em) {
        .cs-grid-layout__brick--x2 {
          grid-column-end: span 2; }
        .cs-grid-layout__brick--y2 {
          grid-row-end: span 2; } }
      @media (min-width: 80em) {
        .cs-grid-layout__brick--x2 {
          grid-column-end: span 2; }
        .cs-grid-layout__brick--y2 {
          grid-row-end: span 2; } }
      @media (min-width: 90em) {
        .cs-grid-layout__brick--x2 {
          grid-column-end: span 2; }
        .cs-grid-layout__brick--y2 {
          grid-row-end: span 2; } }
      @media (min-width: 120em) {
        .cs-grid-layout__brick--x2 {
          grid-column-end: span 2; }
        .cs-grid-layout__brick--y2 {
          grid-row-end: span 2; } }
      @media (min-width: 30em) {
        .cs-grid-layout:not(.cs-grid-layout--with-sidebar) .cs-grid-layout__brick:not(.cs-grid-layout__brick--x2) .cs-image-teaser__aspect-ratio {
          padding-top: unset !important; } }
      @media (min-width: 40em) {
        .cs-grid-layout:not(.cs-grid-layout--with-sidebar) .cs-grid-layout__brick:not(.cs-grid-layout__brick--x2) .cs-image-teaser__aspect-ratio {
          padding-top: unset !important; } }
      @media (min-width: 48em) {
        .cs-grid-layout:not(.cs-grid-layout--with-sidebar) .cs-grid-layout__brick:not(.cs-grid-layout__brick--x2) .cs-image-teaser__aspect-ratio {
          padding-top: unset !important; }
        .cs-grid-layout:not(.cs-grid-layout--with-sidebar) .cs-grid-layout__brick--x2 .cs-image-teaser__aspect-ratio {
          padding-top: unset !important; } }
      @media (min-width: 64em) {
        .cs-grid-layout:not(.cs-grid-layout--with-sidebar) .cs-grid-layout__brick:not(.cs-grid-layout__brick--x2) .cs-image-teaser__aspect-ratio {
          padding-top: unset !important; }
        .cs-grid-layout:not(.cs-grid-layout--with-sidebar) .cs-grid-layout__brick--x2 .cs-image-teaser__aspect-ratio {
          padding-top: unset !important; } }
      @media (min-width: 80em) {
        .cs-grid-layout:not(.cs-grid-layout--with-sidebar) .cs-grid-layout__brick:not(.cs-grid-layout__brick--x2) .cs-image-teaser__aspect-ratio {
          padding-top: unset !important; }
        .cs-grid-layout:not(.cs-grid-layout--with-sidebar) .cs-grid-layout__brick--x2 .cs-image-teaser__aspect-ratio {
          padding-top: unset !important; } }
      @media (min-width: 90em) {
        .cs-grid-layout:not(.cs-grid-layout--with-sidebar) .cs-grid-layout__brick:not(.cs-grid-layout__brick--x2) .cs-image-teaser__aspect-ratio {
          padding-top: unset !important; }
        .cs-grid-layout:not(.cs-grid-layout--with-sidebar) .cs-grid-layout__brick--x2 .cs-image-teaser__aspect-ratio {
          padding-top: unset !important; } }
      @media (min-width: 120em) {
        .cs-grid-layout:not(.cs-grid-layout--with-sidebar) .cs-grid-layout__brick:not(.cs-grid-layout__brick--x2) .cs-image-teaser__aspect-ratio {
          padding-top: unset !important; }
        .cs-grid-layout:not(.cs-grid-layout--with-sidebar) .cs-grid-layout__brick--x2 .cs-image-teaser__aspect-ratio {
          padding-top: unset !important; } }
      .cs-grid-layout__brick--x2:not(.cs-grid-layout__brick--y2):before {
        padding-top: 0; }
      .cs-grid-layout__brick--y2:not(.cs-grid-layout__brick--x2):before {
        padding-top: 0; }
      .cs-grid-layout__brick, .cs-grid-layout__brick--teaser-ready {
        width: 100% !important;
        display: block; }
      @media (max-width: 29.99em) {
        .cs-grid-layout__brick--teaser-ready {
          grid-column-start: 1 !important; } }
      .cs-grid-layout__brick--right {
        float: none; }
    @media (min-width: 30em) {
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--x2 {
        grid-column-end: span 2; }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--y2 {
        grid-row-end: span 2; }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick:not(.cs-grid-layout__brick--x2) .cs-image-teaser__aspect-ratio {
        padding-top: unset !important; } }
    @media (min-width: 40em) {
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--x2 {
        grid-column-end: span 2; }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--y2 {
        grid-row-end: span 2; }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick:not(.cs-grid-layout__brick--x2) .cs-image-teaser__aspect-ratio {
        padding-top: unset !important; } }
    @media (min-width: 48em) {
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--x2 {
        grid-column-end: span 2; }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--y2 {
        grid-row-end: span 2; }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick:not(.cs-grid-layout__brick--x2) .cs-image-teaser__aspect-ratio {
        padding-top: unset !important; } }
    @media (min-width: 64em) {
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--x2 {
        grid-column-end: span 2; }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--y2 {
        grid-row-end: span 2; }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick:not(.cs-grid-layout__brick--x2) .cs-image-teaser__aspect-ratio {
        padding-top: unset !important; }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--x2 .cs-image-teaser__aspect-ratio {
        padding-top: unset !important; } }
    @media (min-width: 80em) {
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--x2 {
        grid-column-end: span 2; }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--y2 {
        grid-row-end: span 2; }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick:not(.cs-grid-layout__brick--x2) .cs-image-teaser__aspect-ratio {
        padding-top: unset !important; }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--x2 .cs-image-teaser__aspect-ratio {
        padding-top: unset !important; } }
    @media (min-width: 90em) {
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--x2 {
        grid-column-end: span 2; }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--y2 {
        grid-row-end: span 2; }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick:not(.cs-grid-layout__brick--x2) .cs-image-teaser__aspect-ratio {
        padding-top: unset !important; }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--x2 .cs-image-teaser__aspect-ratio {
        padding-top: unset !important; } }
    @media (min-width: 120em) {
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--x2 {
        grid-column-end: span 2; }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--y2 {
        grid-row-end: span 2; }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick:not(.cs-grid-layout__brick--x2) .cs-image-teaser__aspect-ratio {
        padding-top: unset !important; }
      .cs-grid-layout--with-sidebar .cs-grid-layout__brick--x2 .cs-image-teaser__aspect-ratio {
        padding-top: unset !important; } } }
